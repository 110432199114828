import React from 'react';
import PropTypes from 'prop-types';
import { MultiList } from '@appbaseio/reactivesearch';

const Filter = (props) => {
  const { andFilterComponents, orFilterComponents } = props;

  return (
    <MultiList
      react={{
        and: andFilterComponents,
        or: orFilterComponents,
      }}
      size={1000}
      showSearch
      {...props}
    />
  );
};

Filter.defaultProps = {
  andFilterComponents: [],
  orFilterComponents: [],
};

Filter.propTypes = {
  andFilterComponents: PropTypes.arrayOf(PropTypes.string),
  orFilterComponents: PropTypes.arrayOf(PropTypes.string),
};
export default Filter;
