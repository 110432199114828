export const getCompetitorsFilterQuery = competitorIds => ({
  bool: {
    filter: {
      terms: {
        id_si: competitorIds,
      },
    },
  },
});

export const getCatalogFilterQuery = websiteId => ({
  bool: {
    must: {
      term: {
        id_si: websiteId,
      },
    },
    // no products with empty titles
    must_not: {
      term: {
        'nom_prod.keyword': '',
      },
    },
  },
});

export const getWebsiteMarquesQuery = websiteId => ({
  query: {
    bool: {
      must: [
        {
          term: {
            id_si: {
              value: websiteId,
            },
          },
        },
        {
          exists: {
            field: 'nom_marque.keyword',
          },
        },
      ],
    },
  },
});

export const getHideMatchingQuery = () => ({
  bool: {
    should: [
      {
        term: {
          nb_matching: 0,
        },
      },
      {
        bool: {
          must_not: {
            exists: {
              field: 'nb_matching',
            },
          },
        },
      },
    ],
    minimum_should_match: 1,
  },
});

export const generateTextSearch = (value, datasearchProps, matchedProductIds) => ({
  boosting: {
    positive: {
      bool: {
        should: [
          {
            terms: {
              id_prod: matchedProductIds,
            },
          },
          {
            multi_match: {
              query: value,
              fields: datasearchProps.dataField,
              type: 'best_fields',
              operator: datasearchProps.queryFormat,
              fuzziness: '0',
            },
          },
          {
            multi_match: {
              query: value,
              fields: datasearchProps.dataField,
              type: 'best_fields',
              operator: datasearchProps.queryFormat,
              fuzziness: 'AUTO',
            },
          },
          {
            multi_match: {
              query: value,
              fields: datasearchProps.dataField,
              type: 'phrase_prefix',
              operator: datasearchProps.queryFormat,
            },
          },
        ],
        minimum_should_match: '1',
      },
    },
    negative: {
      bool: {
        must_not: {
          terms: {
            id_prod: matchedProductIds,
          },
        },
      },
    },
    negative_boost: 0.0001,
  },
});

export const getSiteAggregationQuery = websiteIds => ({
  query: {
    bool: {
      filter: {
        terms: {
          id_si: websiteIds,
        },
      },
    },
  },
  size: 0,
  aggs: {
    meta_aggs: {
      terms: {
        field: 'id_si',
        size: 1000,
      },
      aggs: {
        nom_si: {
          terms: {
            field: 'nom_si.keyword',
            size: 1,
          },
        },
        reference: {
          terms: {
            field: 'reference.keyword',
            size: 1,
          },
        },
        ean: {
          terms: {
            field: 'ean.keyword',
            size: 1,
          },
        },
        ref_fabriquant: {
          terms: {
            field: 'ref_fabriquant.keyword',
            size: 1,
          },
        },
        ref_fournisseur: {
          terms: {
            field: 'ref_fournisseur.keyword',
            size: 1,
          },
        },
        prix_prod: {
          terms: {
            field: 'prix_prod',
            exclude: [0],
            size: 1,
          },
        },
        marque: {
          terms: {
            field: 'nom_marque.keyword',
            size: 20,
          },
        },
        date_last_crawl: {
          date_range: {
            field: 'date_last_crawl',
            format: 'yyyy/MM/dd HH:mm:ss',
            ranges: [
              { from: 'now-1d' },
            ],
          },
        },
      },
    },
  },
});

export const getProductNotMachingByIdsProduct = (websiteId, idsProduct) => ({
  bool: {
    must: {
      term: {
        id_si: {
          value: websiteId,
        },
      },
    },
    // no products matching
    must_not: {
      terms: {
        id_prod: idsProduct,
      },
    },
  },
});

export const productsFavoriteQuery = siteId => ({
  bool: {
    must: [
      {
        term: {
          id_si: siteId,
        },
      },
      {
        term: {
          star_prod: {
            value: 1,
          },
        },
      },
    ],
  },
});

export const productsActiveQuery = siteId => ({
  bool: {
    must: [
      {
        term: {
          id_si: siteId,
        },
      },
    ],
    should: [
      {
        term: {
          active: {
            value: '1',
          },
        },
      },
      {
        bool: {
          must_not: {
            exists: {
              field: 'active',
            },
          },
        },
      },
    ],
    minimum_should_match: 1,
  },
});

export const querySortLastCrawl = () => ({
  query: {
    bool: {
      filter: {
        terms: {
          id_si: [4],
        },
      },
    },
  },
  sort: {
    date_last_crawl: {
      order: 'desc',
    },
  },
});
