import React, { Component } from 'react';
import { Switch, Route, useLocation } from 'react-router-dom';
import { MuiThemeProvider, createMuiTheme } from '@material-ui/core/styles';
import { green, blue, red } from '@material-ui/core/colors';
import Popup from './components/pages/Popup';
import Admin from './components/pages/Admin';
import Demo from './components/pages/Demo';
import DemoSites from './components/pages/DemoSites';
import MatchingBatch from './components/pages/MatchingBatch';
import './styles/App.css';
import SiteStatistics from './components/pages/SiteStatistics';
import { setLocaletranslation } from './helper/translate';

const muiTheme = createMuiTheme({
  palette: {
    primary: green,
    secondary: blue,
    error: red,
  },
});

// eslint-disable-next-line react/prefer-stateless-function
class App extends Component {
  render() {
    return (
      <MuiThemeProvider theme={muiTheme}>
        <Switch>
          <Route exact path="/" component={Demo} />
          <Route exact path="/sites" component={DemoSites} />
          <Route exact path="/admin" component={Admin} />
          <Route path="/:locale/website/:website_id/matching_popup" component={Popup} />
          <Route path="/:locale/website/:website_id/matching_batch" component={MatchingBatch} />
          <Route path="/website/:website_id/matching_popup" component={Popup} />
          <Route path="/website/:website_id/matching_batch" component={MatchingBatch} />
          <Route exact path="/website/:site_id/statistics" component={SiteStatistics} />
          <Route exact path="/website/statistics" component={SiteStatistics} />
        </Switch>
      </MuiThemeProvider>
    );
  }
}

export default App;
