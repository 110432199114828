import React, { Component } from 'react';
import { withStyles } from '@material-ui/core/styles';
import FormGroup from '@material-ui/core/FormGroup';
import FormControlLabel from '@material-ui/core/FormControlLabel';
import Switch from '@material-ui/core/Switch';
import PropTypes from 'prop-types';


const IOSSwitchCustomer = withStyles(theme => ({
  root: {
    width: 70,
    height: 30,
    padding: 0,
    margin: theme.spacing(1) ,
  },
  switchBase: {
    padding: 1,
    '&$checked': {
      transform: 'translateX(40px)',
      color: theme.palette.common.white,
      '& + $track': {
        backgroundColor: '#52d869',
        opacity: 1,
        border: 'none',
      },
    },
    '&$focusVisible $thumb': {
      color: '#52d869',
      border: '6px solid #fff',
    },
  },
  thumb: {
    width: 28,
    height: 28,
    
  },
  track: {
    borderRadius: 50 / 2,
    border: `0px solid ${theme.palette.grey[400]}`,
    backgroundColor: '#ff1966',
    opacity: 1,
    transition: theme.transitions.create(['background-color', 'border']),
  },
  checked: {},
  focusVisible: {},
}))(({ classes, ...props }) => (
  <Switch
    focusVisibleClassName={classes.focusVisible}
    disableRipple
    classes={{
      root: classes.root,
      switchBase: classes.switchBase,
      thumb: classes.thumb,
      track: classes.track,
      checked: classes.checked,
    }}
    {...props}
  />
));

class IOSSwitch extends Component {
  constructor(props) {
    super(props);
    this.handleChange = this.handleChange.bind(this);
    this.state = {
      ischecked: true,
    };
  }

  handleChange = name => (event) => {
    this.setState({[name]: event.target.checked }, ()=>{this.props.onChangeSwitch(this.state.ischecked);});    
  }
  render() {
    return (
      <FormGroup>
        <FormControlLabel
          control={(
            <IOSSwitchCustomer
              checked={this.state.ischecked}
              onChange={this.handleChange('ischecked')}
              value="ischecked"
            />
          )}
        />
      </FormGroup>
    );
  }
}

export default IOSSwitch;
IOSSwitch.propTypes = {
  onChangeSwitch: PropTypes.func,
};

IOSSwitch.defaultProps = {
  onChangeSwitch: () => { },
};
