import React, { Component } from 'react';
import PropTypes from 'prop-types';
import styled from '@emotion/styled';
import { t } from 'ttag';
import qs from 'qs';
import {
  Snackbar, Slide, Grid, Paper, RadioGroup, FormControlLabel, Radio, Button, FormControl, Select
} from '@material-ui/core';
import { ReactiveBase, SingleDropdownList } from '@appbaseio/reactivesearch';
import CatalogMatchingBatch from '../ui/CatalogMatchingBatch';
import '../../styles/Popup.css';
import { getModesEnum } from '../../helper/utils';
import callApi from '../../helper/api-fetch';
import { WebsiteController, FilterMachingByIdsProduct } from '../datacontroller/controllers';
import ReactDataSearch from '../reactivesearch/ReactDataSearch';
import { setLocaletranslation } from '../../helper/translate';

const NumberMatching = styled('div')`
    font-size: 76px;
    color: #000000; 
    margin-bottom : 20px;
`;

const Flex = styled('div')`
  display: flex;
  justify-content: space-between;
  margin-bottom: 10px;
  margin-top: 10px;
`;

const VFlex = styled('div')`
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  margin-bottom: 10px;
  margin-top: 10px;
`;

class MatchingBatch extends Component {
  constructor(props) {
    super(props);
    const { match, location } = props;
    this.notifyUser = this.notifyUser.bind(this);
    const locale = match.params.locale ? match.params.locale : 'fr';
    setLocaletranslation(locale);
    const websiteId = parseInt(match.params.website_id, 10);
    const parsed = qs.parse(location.search.replace('?', ''));
    this.sizeProductByPage = parsed.size ? parseInt(parsed.size, 10) : 20;
    this.selectBrandAllLabel = 'Tous les marques'
    this.state = {
      websiteId: null,
      mode: null,
      competitorIds: [],
      websiteStates: {},
      notification: null,
      competitorIdDefault: parsed.competitor_id ? parseInt(parsed.competitor_id, 10) : null,
      siteNames: {},
      currentFilters: {},
      typeCatalog: 'client',
      isLoadApi: false,
      isLoadCatalog: true,
      idsProductFiltered: [],
      websiteIdCurrent: null,
      matchingsToAdd: [],
      currentFiltersMarque: '',
      inputValue: '',
      competitors: [],
      currentFiltersMarqueCompetitor: '',
      isBrand : false
    };

    if (!websiteId) {
      this.state.notification = t`Aucun site indiqué`;
    } else {
      this.state.websiteId = parseInt(websiteId, 10);
      this.state.websiteIdCurrent = parseInt(websiteId, 10);
    }
    this.handleFilterBrandValueChangeClient = this.handleFilterBrandValueChangeClient.bind(this);
    this.handleFilterBrandValueChangeCompetitor = this.handleFilterBrandValueChangeCompetitor.bind(this);
    this.handleInputValueChange = this.handleInputValueChange.bind(this);
    this.handleChangeCatalog = this.handleChangeCatalog.bind(this);
    this.updateMatchingsToAdd = this.updateMatchingsToAdd.bind(this);
    this.handValideMatchings = this.handValideMatchings.bind(this);
    this.handRefuseMatchings = this.handRefuseMatchings.bind(this);
    this.handleChangeCatalogueCompetitor = this.handleChangeCatalogueCompetitor.bind(this);
  }

  componentDidMount() {
    const { websiteId } = this.state;
    this.loadCompetitors(websiteId);
  }

  loadCompetitors(websiteId) {
    const { competitorIdDefault } = this.state;
    callApi({
      path: `/sites/${websiteId}`,
      onSuccess: (data) => {
        const modes = getModesEnum();
        this.setState({ mode: data.marque ? modes.marque : modes.webshop });
        const competitorIdsApi = data.concurrentIds.map(idStr => parseInt(idStr, 10));
        if (data.concurrentIds.length === 0) {
          return Promise.reject(new Error(t`Le site en cours n'a pas de concurrents surveillés`));
        }
        if (competitorIdDefault && !competitorIdsApi.includes(competitorIdDefault)) {
          return Promise.reject(new Error(t`Le site n'est pas vos concurrents surveillés`));
        }
        this.setState({
          competitorIds: [competitorIdDefault ? competitorIdDefault : parseInt(data.concurrentIds[0], 10)],
          competitors: data.concurrentNames
        },
          () => {
            this.setState({
              siteNames: { ...{ [websiteId]: data.name }, ...data.concurrentNames },
              competitorIdDefault: competitorIdDefault ? competitorIdDefault : parseInt(data.concurrentIds[0], 10),
              isBrand: data.marque
            });
          }
        );
        this.loadProductMatching();
        return null;
      },
      onError: error => this.notifyUser(error.message),
      errorMessages: {
        404: t`Le site indiqué n'existe pas`,
      },
    });
  }

  loadProductMatching() {
    const { competitorIdDefault, websiteId, websiteIdCurrent } = this.state;
    return (
      callApi({
        path: `/sites/${websiteId}/matching-competitor/true/${competitorIdDefault}/?isBestPrice=false`,
        onSuccess: (dataMatching) => {
          const idsProductCompetitor = [];
          let idsProductClient = [];
          Object.values(dataMatching.produitsRels).map(idProd => Array.prototype.push.apply(idsProductCompetitor, idProd.id_prod_concu));
          idsProductClient = Object.keys(dataMatching.produitsRels).map(idProd => parseInt(idProd, 10));
          this.setState({
            idsProductFiltered: websiteIdCurrent === websiteId ? idsProductClient : idsProductCompetitor.map(idProd => parseInt(idProd, 10)),
            isLoadApi: true,
          });
        },
        onError: error => this.notifyUser(error.message),
      })
    );
  }

  notifyUser(message) {
    this.setState({ notification: message.toUpperCase() });
  }

  handleFilterBrandValueChangeClient(value) {
    this.setState({
      matchingsToAdd: [],
      isLoadCatalog: false,
      currentFiltersMarque: value === this.selectBrandAllLabel ? '' : value,
    }, () => {
      this.setState({
        currentFiltersMarqueCompetitor: '',
        isLoadCatalog: true,
      });
    });
  }

  handleFilterBrandValueChangeCompetitor(value) {
    this.setState({
      matchingsToAdd: [],
      isLoadCatalog: false,
      currentFiltersMarqueCompetitor: value === this.selectBrandAllLabel ? '' : value,
    }, () => {
      this.setState({
        isLoadCatalog: true,
      });
    });
  }
  handleInputValueChange(value) {
    this.setState({
      matchingsToAdd: [],
      isLoadCatalog: false,
      inputValue: `${value}`,
    }, () => {
      this.setState({
        isLoadCatalog: true,
      });
    });
  }

  updateMatchingsToAdd(idProductClient, idProductCompetitor, isChecked) {
    const { typeCatalog } = this.state;

    if (idProductClient) {
      if (isChecked) {
        this.setState(prevState => ({ idsProductFiltered: [...prevState.idsProductFiltered, idProductClient] }));
      }
    }

    let idProductClientMatching = idProductClient;
    let idProductCompetitorMatching = idProductCompetitor;
    if (typeCatalog !== 'client') {
      idProductClientMatching = idProductCompetitor;
      idProductCompetitorMatching = idProductClient;
    }
    if (idProductCompetitorMatching) {
      if (isChecked) {
        this.setState(prevState => ({
          matchingsToAdd: [...prevState.matchingsToAdd, { id_prod: idProductClientMatching, id_prod_concu: idProductCompetitorMatching }],
        }));
      } else {
        this.setState(prevState => ({
          matchingsToAdd: prevState.matchingsToAdd.filter(
            item => item.id_prod !== idProductClientMatching,
          ),
        }));
      }
    }
  }

  handleChangeCatalog = name => (event) => {
    const {
      websiteId, competitorIdDefault
    } = this.state;
    this.setState({
      isLoadApi: false,
      currentFilters: {},
      matchingsToAdd: [],
      currentFiltersMarque: '',
      inputValue: '',
      websiteIdCurrent: event.target.value === 'client' ? websiteId : competitorIdDefault,
      competitorIds: event.target.value === 'client' ? [competitorIdDefault] : [websiteId],
      typeCatalog: event.target.value
    },
      () => {
        this.loadProductMatching();
      });
  }

  handValideMatchings() {
    const { matchingsToAdd, websiteId, competitorIdDefault } = this.state;
    const path = `/sites/${websiteId}/addmatchingsbybatch/${competitorIdDefault}/?relationsmatching=${encodeURIComponent(JSON.stringify(matchingsToAdd))}`;
    callApi({
      path,
      onSuccess: (data) => {
        this.setState({
          isLoadApi: false,
          isLoadCatalog: false,
          matchingsToAdd: [],
        }, () => { this.setState({ isLoadApi: true, isLoadCatalog: true }); });
      },
      onError: error => this.notifyUser(error.message),
    });
  }


  handRefuseMatchings() {
    this.setState({
      isLoadCatalog: false,
      matchingsToAdd: [],
      isLoadApi: false,
    }, () => { this.setState(prevState => ({ idsProductFiltered: prevState.idsProductFiltered, isLoadCatalog: true, isLoadApi: true })); });
  }

  handleChangeCatalogueCompetitor = name => event => {
    const {
      websiteId, typeCatalog
    } = this.state;
    this.setState({
      isLoadApi: false,
      currentFilters: {},
      matchingsToAdd: [],
      currentFiltersMarque: '',
      inputValue: '',
      websiteIdCurrent: typeCatalog === 'client' ? websiteId : event.target.value,
      competitorIds: typeCatalog === 'client' ? [parseInt(event.target.value, 10)] : [websiteId],
      competitorIdDefault: parseInt(event.target.value, 10)
    },
      () => {
        this.loadProductMatching();
      });
  }
  customQueryReactDataSearch(value) {
    if (value) {
      return {
        query: {
          bool: {
            should: [
              {
                match: {
                  nom_prod: {
                    query: value,
                    operator: "and",
                    fuzziness: 0
                  }
                }
              },
              {
                match: {
                  ean: {
                    query: value,
                    operator: "or",
                    fuzziness: 0
                  }
                }
              },
              {
                match: {
                  reference: {
                    query: value,
                    operator: "or",
                    fuzziness: 0
                  }
                }
              },
              {
                match: {
                  ref_fabriquant: {
                    query: value,
                    operator: "or",
                    fuzziness: 0
                  }
                }
              }
            ],
            minimum_should_match: 1,
          }
        }
      }
    } else {
      return {
        query: {
          match_all: {
          }
        }
      }
    }
  }
  render() {
    const {
      websiteId,
      competitorIdDefault,
      notification,
      mode,
      competitorIds,
      websiteStates,
      currentFilters,
      idsProductFiltered,
      websiteIdCurrent,
      typeCatalog,
      isLoadApi,
      matchingsToAdd,
      siteNames,
      currentFiltersMarque,
      isLoadCatalog,
      inputValue,
      competitors,
      currentFiltersMarqueCompetitor,
      isBrand

    } = this.state;

    return (
      <div style={{ margin: 'auto', overflow: 'hidden' }}>
        {mode !== null && isLoadApi
          && (
            <ReactiveBase
              app={process.env.REACT_APP_ELASTIC_INDEX}
              url={process.env.REACT_APP_ELASTIC_ENDPOINT}
              credentials={process.env.REACT_APP_PROXY_CREDENTIALS}
            >
              <WebsiteController websiteId={websiteIdCurrent} componentId="websiteFilter" />
              <WebsiteController websiteId={websiteIdCurrent == websiteId ? competitorIdDefault : websiteId } componentId="websiteFilterCompetitor" />
              <FilterMachingByIdsProduct websiteId={websiteIdCurrent} componentId="hideMatchingsFilterByIds" idsProduct={idsProductFiltered} />
              <div style={{ borderBottomWidth: '1px', borderBottomColor: '#CECECE', margin: '20px', borderBottomStyle: 'solid' }} variant="outlined" square>
                <Grid container>
                  <Grid item xs={3} style = {{ marginTop: '0px', borderStyle: 'solid', borderColor: '#CCCCCC', borderWidth:'1px 1px 0px 1px', padding: '20px', textAlign : 'center' }} >
                    <NumberMatching style = {{ marginTop: '30px', marginBottom :'50px'}} >
                      {matchingsToAdd.length}
                      <p style={{ fontSize: '18px' }}>{t`ASSOCIATIONS POSSIBLES`}</p>
                    </NumberMatching>
                    <RadioGroup aria-label="gender" name="catologType" value={typeCatalog} onChange={this.handleChangeCatalog('catologType')}>
                      <VFlex>
                        <div style = {{ marginBottom : '20px', width : '100%', textAlign : 'left' , fontWeight : '500' }}>{t`Je veux faire des associations avec`}: </div>
                        <FormControl variant="outlined" >
                          <Select
                            native
                            value= {typeCatalog === 'competitor' ? websiteIdCurrent : competitorIdDefault}
                            name="selectCatalogue"
                            onChange={this.handleChangeCatalogueCompetitor("selectCatalogue")}
                            style ={{ width : '80%', marginLeft : '20px'}}
                          >
                            {
                              Object.entries(competitors).map(([key, value]) => (
                                <option key={key} value={key}>{value}</option>
                              ))
                            }
                          </Select>
                        </FormControl>
                        <div style = {{ marginTop : '40px', marginBottom : '10px', width : '100%', textAlign : 'left' ,  fontWeight : '500'}}>{t`Le catalogue de référence sera`}: </div>
  
                        <FormControlLabel value="client" control={<Radio />} label={t`Mon catalogue`}  style ={{ width : '80%', marginLeft : '10px', fontSize: '14px'}}/>
                        <FormControlLabel value="competitor" control={<Radio />} label={ isBrand ? t`Le catalogue du revendeur` : t`Le catalogue du concurrent`} style={{ marginBottom: '10px' , width : '80%', marginLeft : '10px' , fontSize: '14px'}} />

                        
                      </VFlex>
                    </RadioGroup>
                    <div style={{ marginTop: '50px', display : 'flex' , flexDirection : 'column', alignItems: 'center' }}>
                    <Button variant="contained" color="primary" onClick={() => { this.handValideMatchings(); }} style={{ color: '#fff', boxShadow: 'none', marginBottom : '20px' , width :'66%' }}>
                      <font>{t`Valider`} {t`les`}&nbsp;</font>
                      <span style={{ fontSize: '30px', lineHeight: '29px' }}>{matchingsToAdd.length}</span>
                      <font>&nbsp;{t`matching`}</font>
                    </Button>
                    <Button variant="contained" onClick={() => { this.handRefuseMatchings(); }} style={{ marginRight: '10px', boxShadow: 'none', width: '37%' }}>
                      <font> {t`Tout Refuser`} </font>
                    </Button>
                    </div>
                  </Grid>
                  <Grid item xs={9} style={{ paddingLeft: '20px' }}>
                    <Grid container>
                      <Grid item xs={12} style={{ padding: '0px 0px 10px 0px' }}>
                        <ReactDataSearch
                          componentId="searchInput"
                          dataField={['nom_prod', 'reference', 'ref_fabriquant', 'ref_fournisseur', 'ean']}
                          defaultSelected={inputValue}
                          reactDependent={['websiteFilter', 'hideMatchingsFilterByIds', 'catalogMarqueFilter']}
                          placeholder={t`Rechercher parmi vos produits`}
                          onValueChange={this.handleInputValueChange}
                          debounce={5000}
                          style={{ width: '100%' }}
                          customQuery={
                            function (value, props) { this.customQueryReactDataSearch(value) }
                          }
                        />
                      </Grid>
                      <Grid item xs={5} style={{ padding: '20px 0px 20px 0px' }}>
                        <SingleDropdownList
                          componentId="catalogMarqueFilter"
                          dataField="nom_marque.keyword"
                          placeholder={t`Filtrer par marque` + " " + siteNames[websiteIdCurrent]} 
                          filterLabel="marque"
                          selectAllLabel={this.selectBrandAllLabel}
                          sortBy="asc"
                          size={1000000}
                          debounce={5000}
                          defaultSelected={currentFiltersMarque}
                          showMissing={false}
                          showSearch
                          react={{
                            and: ['websiteFilter', 'searchInput', 'hideMatchingsFilterByIds'],
                          }}
                          className="marqueFilter"
                          innerClass={{ select: 'select', list: 'list' }}
                          onValueChange={this.handleFilterBrandValueChangeClient}
                        /></Grid>
                      <Grid item xs={2} />
                      <Grid item xs={5} style={{ padding: '20px 0px 20px 0px' }}>
                        <SingleDropdownList
                          componentId="catalogBrandFilterCompetitor"
                          dataField="nom_marque.keyword"
                          placeholder={t`Filtrer par marque`+ websiteIdCurrent === websiteId ? siteNames[competitorIdDefault] : siteNames[websiteId] } 
                          filterLabel={t`Marque`}
                          selectAllLabel={this.selectBrandAllLabel}
                          sortBy="asc"
                          size={1000000}
                          debounce={5000}
                          showSearch
                          defaultSelected={currentFiltersMarqueCompetitor}
                          showMissing={false}
                          className="marqueFilter"
                          innerClass={{ select: 'select', list: 'list' }}
                          onValueChange={this.handleFilterBrandValueChangeCompetitor}
                          react={{
                            and: ['websiteFilterCompetitor'],
                          }}
                        />
                      </Grid>
                      <Grid item xs={12}>

                        {isLoadCatalog && (
                          <CatalogMatchingBatch
                            mode={mode}
                            websiteStates={websiteStates}
                            competitorIds={competitorIds}
                            currentFilters={currentFilters}
                            searchComponents={['websiteFilter', 'searchInput', 'catalogMarqueFilter', 'hideMatchingsFilterByIds']}
                            size={this.sizeProductByPage}
                            updateMatchingsToAdd={this.updateMatchingsToAdd}
                            isLoadCatalog={isLoadCatalog}
                            FilterBrandCompetitor={currentFiltersMarqueCompetitor}
                          />
                        )}
                      </Grid>
                    </Grid>
                  </Grid>
                </Grid>
              </div>
            </ReactiveBase>
          )}
        <Snackbar
          anchorOrigin={{ vertical: 'bottom', horizontal: 'center' }}
          message={notification}
          autoHideDuration={6000}
          onClose={() => {
            this.setState({ notification: null });
          }}
          open={!!notification}
          TransitionComponent={props => <Slide {...props} direction="up" />}
        />
      </div>
    );
  }
}

MatchingBatch.propTypes = {
  match: PropTypes.shape({
    params: PropTypes.shape({
      website_id: PropTypes.string.isRequired,
    }),
  }).isRequired,
  location: PropTypes.shape({
    search: PropTypes.string,
  }).isRequired,
};
export default MatchingBatch;
