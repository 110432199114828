import React from 'react';
import PropTypes from 'prop-types';
import {
  DataSearch,
} from '@appbaseio/reactivesearch';


const ReactDataSearch = (props) => {
  const {
    dataField, componentId, onValueChange, reactDependent, placeholder, debounce, defaultSelected,
  } = props;
  return (
    <DataSearch
      componentId={componentId}
      dataField={dataField}
      defaultSelected={defaultSelected}
      highlightField={['nom_prod', 'reference', 'ref_fabriquant', 'ref_fournisseur', 'ean']}
      highlight
      react={{
        and: reactDependent,
      }}
      placeholder={placeholder}
      autosuggest={false}
      debounce={debounce}
      fuzziness="AUTO"
      queryFormat="or"
      showFilter={false}
      className="search"
      onValueChange={onValueChange}
      customHighlight={() => ({
        highlight: {
          pre_tags: ['<mark>'],
          post_tags: ['</mark>'],
          fields: {
              ean: {},
              nom_prod: {},
              ref_fournisseur: {},
              ref_fabriquant: {},
              reference: {}
          },
          number_of_fragments: 0,
      },
      })
    }

    />
  );
};

ReactDataSearch.propTypes = {
  dataField: PropTypes.arrayOf(PropTypes.string).isRequired,
  onValueChange: PropTypes.func.isRequired,
  componentId: PropTypes.string.isRequired,
  reactDependent: PropTypes.arrayOf(PropTypes.string).isRequired,
  placeholder: PropTypes.string.isRequired,
  debounce: PropTypes.number,
  defaultSelected: PropTypes.string,
};

ReactDataSearch.defaultProps = {
  debounce: 500,
  defaultSelected: '',
};

export default ReactDataSearch;
