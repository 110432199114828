/* eslint no-underscore-dangle: 0 */
/* eslint no-unused-expressions: [2, { allowShortCircuit: true }] */
import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { t } from 'ttag';
import {
  ReactiveList,

} from '@appbaseio/reactivesearch';
import {
  cleanElasticResult,
  getLoader,
} from '../../helper/utils';

import ItemSuggestion from './ProductMatchingBatch/ItemSuggestion';
import { callElastic } from '../../helper/api-fetch';

class CatalogMatchingBatch extends Component {
  constructor(props) {
    super(props);
    this.currentTimer = null;
  }

  static getProductByNameFilterCompetitor(elasticResult, filters, from) {
    const matchSuggestion = {
      query: {
        bool: {
          should: [
            {
              match: {
                nom_prod: {
                  query: elasticResult.nom_prod,
                  operator: 'or',
                  fuzziness: 0,
                  boost: 40,
                },
              },
            },
            {
              match: {
                ean: {
                  query: elasticResult.nom_prod,
                  operator: 'or',
                  fuzziness: 0,
                  boost: 30,
                },
              },
            },
            {
              match: {
                reference: {
                  query: elasticResult.nom_prod,
                  operator: 'or',
                  fuzziness: 0,
                  boost: 30,
                },
              },
            },
          ],
          filter: filters,
        },
      },
      from,
      size: 1,
    };
    if (elasticResult.reference) {
      matchSuggestion.query.bool.should.push(
        {
          match: {
            reference: {
              query: elasticResult.reference,
              operator: 'or',
              fuzziness: 0,
              boost: 20,
            },
          },
        },
      );
    }
    if (elasticResult.ean) {
      matchSuggestion.query.bool.should.push(
        {
          match: {
            ean: {
              query: elasticResult.ean,
              operator: 'or',
              fuzziness: 0,
              boost: 20,
            },
          },
        },
      );
    }
    return matchSuggestion;
  }

  async findSuggestion(product, competitorIds, FilterBrandCompetitor, from) {
    const filters = FilterBrandCompetitor ? [{ terms: { id_si: competitorIds } }, { terms: { 'nom_marque.keyword': [FilterBrandCompetitor] } }] : [{ terms: { id_si: competitorIds } }];
    const elastic = await callElastic(
      this.constructor.getProductByNameFilterCompetitor(product, filters, from),
    );
    return elastic;
  }

  render() {
    const {
      competitorIds, searchComponents, size, updateMatchingsToAdd, isLoadCatalog, FilterBrandCompetitor,
    } = this.props;
    return isLoadCatalog && (
      <React.Fragment>
        <ReactiveList
          react={{
            and: searchComponents,
          }}
          componentId="productNotMatchings"
          size={size}
          pagination
          paginationAt=""
          loader={getLoader()}
          dataField="nom_prod.keyword"
          style={{ overflowY: 'hidden', overflowX: 'hidden' }}
          showResultStats={false}
          onNoResults={t`Aucun résultat trouvé`}
          innerClass={{ sortOptions: 'sortOptions' }}
          onData={res => (
            <ItemSuggestion
              key={res.id_prod}
              product={res}
              websiteStates={[]}
              getSuggestionElastic={from => this.findSuggestion(cleanElasticResult(res), competitorIds, FilterBrandCompetitor, from)}
              updateMatchingsToAdd={updateMatchingsToAdd}
            />
          )
          }
        />
      </React.Fragment>
    );
  }
}

CatalogMatchingBatch.propTypes = {
  competitorIds: PropTypes.arrayOf(PropTypes.number).isRequired,
  searchComponents: PropTypes.arrayOf(PropTypes.string).isRequired,
  size: PropTypes.number.isRequired,
  updateMatchingsToAdd: PropTypes.func,
  isLoadCatalog: PropTypes.bool.isRequired,
  FilterBrandCompetitor: PropTypes.string,
};

CatalogMatchingBatch.defaultProps = {
  updateMatchingsToAdd: () => { },
  FilterBrandCompetitor: '',
};

export default CatalogMatchingBatch;
