import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { t } from 'ttag';
import { Button } from '@material-ui/core';
import '../../styles/UrlMatching.css';
import generateResultItemCompetitor from '../result/generateResultItemCompetitor';
import { getLoader, getModesEnum } from '../../helper/utils';
import callApi from '../../helper/api-fetch';


class UrlMatching extends Component {
  constructor(props) {
    super(props);
    this.state = {
      productUrl: '',
      candidateProductsData: [],
      pendingRequest: false,
      products: [],
    };

    this.handleChange = this.handleChange.bind(this);
    this.handleSubmit = this.handleSubmit.bind(this);
    this.handleSubmitFetch = this.handleSubmitFetch.bind(this)
  }

  handleChange(event) {
    this.setState({ productUrl: event.target.value });
  }

  handleSubmit(event) {
    event.preventDefault();

    this.setState({
      candidateProductsData: [],
      pendingRequest: true,
      products: [], 
      errorData: false
    });

    const { productUrl } = this.state;
    const { product: { id_prod: productId }, notifHandler, websiteId} = this.props;
    const path = `/products/${productId}/${websiteId}/addmatchingbyurl?urlproductcompetitor=${encodeURIComponent(productUrl)}`;
    callApi({
      path,
      onSuccess: (data) => {
        if (data.hasOwnProperty('message')) {
          this.setState({ pendingRequest: false,  errorData : true});
          notifHandler(data.message)
          return;
        }
        this.setState({
          candidateProductsData: data.map(item => ({
            id_prod: parseInt(item.id_prod, 10),
            is_matched: item.is_matched,
          })),
          products: data
        });
        this.setState({ pendingRequest: false});
      },
      onError: error => {
        notifHandler(error.message);
        this.setState({ pendingRequest: false,  errorData : true});
      },
    });
  }


  handleSubmitFetch(event) {
    event.preventDefault();

    this.setState({
      candidateProductsData: [],
      pendingRequest: true,
      products: []
    });

    const { productUrl } = this.state;
    const { product: { id_prod: productId }, notifHandler } = this.props;
    const urlMatchingUrl = new URL(process.env.REACT_APP_LEGACY_API_ENDPOINT);
    const urlMatchingParams = {
      getoneproduitparurl: 1,
      id_prod: productId,
      url_prod: productUrl,
    };
    Object.keys(urlMatchingParams).forEach(
      key => urlMatchingUrl.searchParams.append(key, urlMatchingParams[key]),
    );
    
    const httpHeaders = new Headers();
    httpHeaders.append('X-Requested-With', 'xmlhttprequest');
    fetch(urlMatchingUrl, { credentials: 'include' })
      .then((response) => {
        if (response.ok) {
          return response.json();
        }
        throw response;
      }, () => Promise.reject(new Error(t`Une erreur réseau est survenue`)))
      .then((data) => {
        if (data.hasOwnProperty('message')) {
          notifHandler(data.message)
          return;
        }
        this.setState({
          candidateProductsData: data.map(item => ({
            id_prod: parseInt(item.id_prod, 10),
            is_matched: item.is_matched,
          })),
          products: data
        });
      })
      .catch((errorOrPromise) => {
        const promise = errorOrPromise instanceof Error
          ? Promise.resolve(errorOrPromise) : errorOrPromise.json();
        promise.then(error => notifHandler(error.message));
      })
      .then(() => {
        this.setState({ pendingRequest: false });
      });
  }

  render() {
    const { productUrl, candidateProductsData, pendingRequest, products , errorData} = this.state;
    const {
      product: { id_prod: productId }, tabSwitchHandler, notifHandler, mode, websiteStates,
    } = this.props;
    const matchedProductIds = candidateProductsData
      .filter(item => item.is_matched)
      .map(item => item.id_prod);
      
    return (
      <div className="url-matching">
        <div className="header-search">
          <div className="switch-tab">
            <Button size="small" onClick={() => tabSwitchHandler(0)}>
              {`+ ${t`Voir les suggestions`}`}
            </Button>
          </div>
        </div>

        <form onSubmit={this.handleSubmit}>
          <h2>
            {mode === getModesEnum().webshop ? t`Ajout par URL du produit concurrent` : t`Ajout par URL du produit chez le revendeur`}
          </h2>
          <input type="url" required placeholder={t`Collez ici l'URL de la page produit`} value={productUrl} onChange={this.handleChange} />
          <Button variant="contained" className="submit" onClick={this.handleSubmit}>
            {t`Valider`}
          </Button>
        </form>

        <div className="result-area">
          {pendingRequest  &&  getLoader()}
          {pendingRequest  && ( <div style={{textAlign: "center", color: "#fe9704" }}>Récupération du produit en cours .... </div>) }
          {candidateProductsData.length > 0 && (
              products.map(product => <div key={product.id_prod}>{generateResultItemCompetitor(parseInt(productId), product, matchedProductIds, notifHandler, () => {}, websiteStates) } </div>)
          )
          }
          {candidateProductsData.length == 0 && !pendingRequest && errorData && (
            <div>{t`Aucun résultat trouvé`}</div>)
          }
        </div>
      </div>
    );
  }
}

UrlMatching.defaultProps = {
  notifHandler: () => { },
};

UrlMatching.propTypes = {
  notifHandler: PropTypes.func,
  tabSwitchHandler: PropTypes.func.isRequired,
  product: PropTypes.shape({ id_prod: PropTypes.number.isRequired }).isRequired,
  mode: PropTypes.number.isRequired,
  // eslint-disable-next-line react/forbid-prop-types
  websiteStates: PropTypes.object.isRequired,
  websiteId : PropTypes.number.isRequired.isRequired
};

export default UrlMatching;
