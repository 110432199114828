import React from 'react';
import PropTypes from 'prop-types';
import styled from '@emotion/styled';
import { Paper, Tooltip } from '@material-ui/core';
import HighlightOffIcon from '@material-ui/icons/HighlightOff';
import AutorenewIcon from '@material-ui/icons/Autorenew';
import { t } from 'ttag';
import { ImageProductMini } from '../Image/ImageProduct';
import { Reference, makeSpanLink, getDomainFromUrl } from '../../../helper/utils';

const Flex = styled('div')`
  display: flex;
  justify-content: space-between;
  & > * {
    margin-top: 3px;
    margin-bottom: 3px;
    margin-right: 15px;
    margin-left: 5px;
  }
`;
const FlexDomain = styled('div')`
  display: flex;
  justify-content: space-between;
  font-size : 14px;
  & > * {
    margin-right: 15px;
  }
`;
const FlexProdName = styled('div')`
  display: flex;
  justify-content: space-between;
  & > * {
    margin-top: 8px;
    margin-right: 15px;
    margin-left: 5px;
  }
`;

const VFlex = styled('div')`
  display: flex;
  flex-direction: column;
  flex-grow: 3;
  width: 80%;

`;

const VFlexDataProd = styled('div')`
  display: flex;
  flex-direction: column;
  flex-grow: 3;
`;

const Brand = styled('div')`
  font-weight: bold;
  text-transform: uppercase;
  border: 1px #999 solid;
  background-color: #999;
  color: #FFF;
  border-radius: 3px;
  padding: 0px 3px;
  margin-bottom:2px;
  width: fit-content;
  font-size: 12px;
`;

const ProductImage = styled('div')`
  height: 60px;
  width: 60px;
  margin: auto;
  text-align: center;
  margin-left: 5px;
  margin-right : 15px;
`;

const ProductName = styled('div')`
    text-overflow: ellipsis;
    width: 75%;
    white-space: nowrap;
    overflow: hidden;
    font-weight: 400;
    margin-left: 0px;
`;

export const ItemProduct = (props) => {
  const { product, isProductClient, onclickAutorenew } = props;
  const prixProdFloat = parseFloat(product.prix_prod).toFixed(2);
  return (
    <Flex>
      <ProductImage>
        <ImageProductMini siteId={product.id_si} name={product.image_nom_prod} alt={product.nom_prod} />
      </ProductImage>
      <VFlex>
        <FlexProdName>
          <ProductName dangerouslySetInnerHTML={{ __html: product.nom_prod }} />
          <div>
            <Tooltip title={t`Archiver ce produit`} placement="top">
              <HighlightOffIcon style={{ color: '#FF7F50' }} />
            </Tooltip>
            {!isProductClient && (
              <Tooltip title={t`Changer de suggestion`} placement="top" onClick={() => { onclickAutorenew(); }} style={{ color: '#cccccc' }}>
                <AutorenewIcon />
              </Tooltip>)}
          </div>
        </FlexProdName>
        <VFlexDataProd>
          {product.nom_marque && (
            <Brand>{product.nom_marque.toUpperCase()}</Brand>
          )}
          <Reference
            value={[product.reference, product.ean, product.ref_fabriquant, product.ref_fournisseur]}
          />
        </VFlexDataProd>
        <FlexDomain>
          <span style={{
            color: '#097212',
          }}
          >
            {makeSpanLink(getDomainFromUrl(product.url_prod), product.url_prod, {
              className: '', 'data-product_id': product.id_prod, style: { color: '#f39c12', textDecorationLine: 'underline' },
            })}
          </span>

          <span style={{ fontSize: '18px', color: '#000', fontWeight: '500' }}>
            {!Number.isNaN(parseInt(prixProdFloat, 10)) && prixProdFloat > 0 && prixProdFloat}
          </span>
        </FlexDomain>
      </VFlex>
    </Flex>
  );
};

ItemProduct.propTypes = {
  product: PropTypes.shape({
    id_si: PropTypes.number.isRequired,
    image_nom_prod: PropTypes.string,
    id_prod: PropTypes.number,
    nom_prod: PropTypes.string,
    url_prod: PropTypes.string,
    star_prod: PropTypes.number,
    prix_prod: PropTypes.any,
  }).isRequired,
  isProductClient: PropTypes.bool,
  onclickAutorenew: PropTypes.func,
};
ItemProduct.defaultProps = {
  isProductClient: true,
  onclickAutorenew: () => { },
};

export default ItemProduct;
