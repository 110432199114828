import React, { Component } from 'react';
import {
  ReactiveBase,
  DataSearch,
  ResultList,
  DynamicRangeSlider,
} from '@appbaseio/reactivesearch';
import { Switch } from '@material-ui/core';
import { getImagePath, stripTags } from '../../helper/utils';
import Header from '../ui/Header';
import '../../styles/Admin.css';

class Admin extends Component {
  constructor(props) {
    super(props);
    this.state = {
      fuzzyEnabled: true,
    };

    this.toggleFuzziness = this.toggleFuzziness.bind(this);
  }

  toggleFuzziness() {
    this.setState(prevState => ({
      fuzzyEnabled: !prevState.fuzzyEnabled,
    }));
  }

  render() {
    const { fuzzyEnabled } = this.state;
    return (
      <ReactiveBase
        app={process.env.REACT_APP_ELASTIC_INDEX}
        url={process.env.REACT_APP_ELASTIC_ENDPOINT}
        credentials={process.env.REACT_APP_PROXY_CREDENTIALS}
      >
        <Header>
          <DataSearch
            className="mainSearch"
            componentId="mainSearch"
            placeholder="Nom du produit"
            queryFormat={fuzzyEnabled ? 'or' : 'and'}
            highlight
            fuzziness={fuzzyEnabled ? 'AUTO' : 0}
            debounce={200}
            autosuggest={false}
            dataField={['nom_prod', 'url_prod']}
          />

          <div>
            <span>
              {'Recherche exacte'}
            </span>
            <Switch checked={!fuzzyEnabled} onChange={this.toggleFuzziness} />
          </div>

          <DynamicRangeSlider
            componentId="crawlDateSensor"
            dataField="date_last_crawl"
            rangeLabels={(min, max) => {
              const timestampToDate = (timestamp) => {
                const date = new Date(timestamp);
                return `${date.getFullYear()}-${date.getMonth() + 1}-${date.getDay()}`;
              };
              return {
                start: timestampToDate(min),
                end: timestampToDate(max),
              };
            }}
          />
        </Header>

        <ResultList
          componentId="results"
          dataField="nom_prod"
          react={{
            and: ['mainSearch', 'crawlDateSensor'],
          }}
          onData={res => ({
            image: getImagePath(res.id_si, res.image_nom_prod),
            title: res.nom_prod,
            description: (
              <div>
                <p>
                  {'ID produit: '}
                  <b>
                    {res.id_prod}
                  </b>
                </p>
                <p>
                  {'Date import dans elastic: '}
                  <b>
                    {new Date(res['@timestamp']).toLocaleString()}
                  </b>
                </p>
                <p>
                  {'Site: '}
                  <b>
                    {res.id_si}
                  </b>
                </p>
                <p>
                  {'Prix: '}
                  <b>
                    {res.prix_prod ? `${res.prix_prod.toLocaleString()} €` : 'PAS DE PRIX'}
                  </b>
                </p>
                <p>
                  {'Date dernière modification: '}
                  <b>
                    {res.date_edition ? new Date(res.date_edition).toLocaleString() : 'N/A'}
                  </b>
                </p>
                <p>
                  {'Favori: '}
                  <b>
                    {res.star_prod ? 'OUI' : 'NON'}
                  </b>
                </p>
                <p>
                  {/* Stripping tags from the url_prod, they have highlight tags in it */
                  res.url_prod && (
                    <a
                      href={stripTags(res.url_prod)}
                      target="_blank"
                      rel="noopener noreferrer"
                      dangerouslySetInnerHTML={{ __html: res.url_prod }}
                    />
                  )}
                </p>
                <p>
                  {'marque: '}
                  {res.marque}
                </p>
                <p>
                  {'id site: '}
                  {res.id_si}
                </p>
                <p>
                  {'ean: '}
                  {res.ean}
                </p>
                <p>
                  {'reference: '}
                  {res.reference}
                </p>
                <p>
                  {'ref_fabriquant: '}
                  {res.ref_fabriquant}
                </p>
                <p>
                  {'ref_fournisseur: '}
                  {res.ref_fournisseur}
                </p>
              </div>
            ),
          })}
        />
      </ReactiveBase>
    );
  }
}

export default Admin;
