import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { t } from 'ttag';
import { DataSearch } from '@appbaseio/reactivesearch';
import { generateTextSearch } from '../../helper/es-queries';

class CursorDataSearch extends Component {
  constructor(props) {
    super(props);

    this.state = {
      currentValue: props.initValue,
    };
    this.inputRef = React.createRef();
    this.datasearchElem = null;
    this.getSearchQueryTerm = this.getSearchQueryTerm.bind(this);
  }

  componentDidMount() {
    const inputElem = this.inputRef.current;
    const { datasearchElem } = this;
    datasearchElem.parentNode.insertBefore(inputElem, datasearchElem);
    inputElem.setAttribute('class', datasearchElem.getAttribute('class'));
    datasearchElem.remove();
  }

  getSearchQueryTerm() {
    const { currentValue } = this.state;
    const { marques } = this.props;
    return `${currentValue} ${marques.join(' ')}`;
  }

  render() {
    const { matchedProductIds, initValue } = this.props;

    return (
      <React.Fragment>
        <input
          ref={this.inputRef}
          placeholder={t`Rechercher chez tous mes concurrents`}
          defaultValue={initValue}
          onInput={(event) => {
            this.setState({ currentValue: event.target.value });
          }}
        />

        <DataSearch
          innerRef={(elem) => { this.datasearchElem = elem; }}
          componentId="mainSearch"
          className="prixan-search-field"
          queryFormat="or"
          autosuggest={false}
          showFilter={false}
          highlight
          debounce={200}
          dataField={['nom_prod^3', 'url_prod', 'marque^2', 'ean^2',  'reference', 'ref_fabriquant', 'ref_fournisseur']}
          highlightField={['nom_prod']}
          fuzziness="AUTO"
          customQuery={(value, props) => generateTextSearch(value, props, matchedProductIds)}
          defaultSelected={this.getSearchQueryTerm()}
          react={{ and: ['marqueFilter'] }}
          customHighlight={() => ({
            highlight: {
              pre_tags: ['<mark>'],
              post_tags: ['</mark>'],
              fields: {
                  ean: {},
                  nom_prod: {},
                  ref_fournisseur: {},
                  ref_fabriquant: {},
                  reference: {}
              },
              number_of_fragments: 0,
          },
          })
        }
        />
      </React.Fragment>
    );
  }
}

CursorDataSearch.defaultProps = {
  initValue: null,
  matchedProductIds: [],
  marques: [],
};
CursorDataSearch.propTypes = {
  initValue: PropTypes.string,
  matchedProductIds: PropTypes.arrayOf(PropTypes.number),
  marques: PropTypes.arrayOf(PropTypes.string),
};

export default CursorDataSearch;
