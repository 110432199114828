/* eslint no-unused-expressions: [2, { allowShortCircuit: true }] */
import React from 'react';
import PropTypes from 'prop-types';
import { DataController } from '@appbaseio/reactivesearch';
import {
  getCompetitorsFilterQuery, getHideMatchingQuery, getCatalogFilterQuery, getProductNotMachingByIdsProduct, productsActiveQuery, productsFavoriteQuery, querySortLastCrawl,
} from '../../helper/es-queries';

export const CompetitorsController = (props) => {
  const { competitorIds } = props;
  return competitorIds.length === 0
    ? (<React.Fragment />)
    : (<DataController
      componentId="competitorFilter"
      customQuery={() => getCompetitorsFilterQuery(competitorIds)}
    />
    );
};

CompetitorsController.propTypes = {
  competitorIds: PropTypes.arrayOf(PropTypes.number).isRequired,
};

export const FilterMachingByIdsProduct = (props) => {
  const { componentId, idsProduct, websiteId } = props;
  return !idsProduct.length
    ? (<React.Fragment />)
    : (<DataController
      componentId={componentId}
      customQuery={() => getProductNotMachingByIdsProduct(websiteId, idsProduct)}
    />
    );
};

FilterMachingByIdsProduct.propTypes = {
  componentId: PropTypes.string.isRequired,
  idsProduct: PropTypes.arrayOf(PropTypes.number).isRequired,
  websiteId: PropTypes.number.isRequired,
};


export const HideMatchingController = (props) => {
  const { hideMatchings, componentId } = props;

  return !hideMatchings
    ? (<React.Fragment />)
    : (<DataController
      componentId={componentId}
      customQuery={getHideMatchingQuery}
    />
    );
};

HideMatchingController.propTypes = {
  hideMatchings: PropTypes.bool.isRequired,
  componentId: PropTypes.string.isRequired,
};

export const WebsiteController = (props) => {
  const { websiteId, componentId } = props;

  return (<DataController
    componentId={componentId}
    customQuery={() => getCatalogFilterQuery(websiteId)}
  />
  );
};

WebsiteController.propTypes = {
  websiteId: PropTypes.number.isRequired,
  componentId: PropTypes.string.isRequired,
};

export const ReferenceController = (props) => {
  const {
    product: {
      reference: mainRef,
      ref_fabriquant: makerRef,
      ref_fournisseur: providerRef,
      ean,
    },
  } = props;

  const queries = [];

  // TODO : reactivate
  false && (mainRef || makerRef || providerRef) && [{
    multi_match: {
      query: [mainRef, makerRef, providerRef].filter(Boolean).join(' '),
      fields: ['reference', 'ref_fournisseur', 'ref_fabriquant', 'nom_prod', 'url_prod'],
      fuzziness: '0',
      type: 'cross_fields',
      operator: 'or',
    },
  }].forEach(query => queries.push(query));

  ean && queries.push({
    multi_match: {
      query: Array.isArray(ean) ? ean.filter(Boolean).join(' ') : ean,
      fields: ['ean', 'nom_prod', 'url_prod'],
      fuzziness: '0',
      type: 'best_fields',
    },
  });
  return queries.length > 0
    ? (
      <DataController
        componentId="matchReferences"
        customQuery={() => ({
          bool: {
            should: queries,
            minimum_should_match: 1,
          },
        })}
      />
    ) : (<React.Fragment />);
};

export const ProductAtiveController = (props) => {
  const { siteId, componentId } = props;
  const customQuery = productsActiveQuery(siteId);
  return (<DataController
    componentId={componentId}
    customQuery={() => customQuery}
  />
  );
};
export const ProductFavoritesController = (props) => {
  const { siteId, componentId } = props;
  const customQuery = productsFavoriteQuery(siteId);
  return (<DataController
    componentId={componentId}
    customQuery={() => customQuery}
  />
  );
};

ReferenceController.defaultProps = {
  product: {
    reference: null,
    ref_fabriquant: null,
    ref_fournisseur: null,
    ean: null,
  },
};
ReferenceController.propTypes = {
  product: PropTypes.shape({
    reference: PropTypes.string,
    ref_fabriquant: PropTypes.string,
    ref_fournisseur: PropTypes.string,
    ean: PropTypes.string,
  }),
};

ProductAtiveController.propTypes = {
  siteId: PropTypes.number.isRequired,
  componentId: PropTypes.string.isRequired,
};

ProductFavoritesController.propTypes = {
  siteId: PropTypes.number.isRequired,
  componentId: PropTypes.string.isRequired,
};

export const SortLastCrawlController = (props) => {
  const { componentId } = props;
  return (<DataController
    componentId={componentId}
    customQuery={() => querySortLastCrawl()}
  />
  );
};
SortLastCrawlController.propTypes = {
  componentId: PropTypes.string.isRequired,
};
