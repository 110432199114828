/* eslint react/prop-types:0 */

import React from 'react';
import { t } from 'ttag';
import LegacyResultList from '@appbaseio/reactivesearch/lib/components/result/ResultList';
import { getLoader, generateGuid } from '../../helper/utils';

const ResultList = (props) => {
  const { innerClass } = props;
  const guid = generateGuid();
  return (
    <div id={guid} className="es-results scrollable scrollable_inflex">
      <LegacyResultList
        showResultStats={false}
        componentId="results"
        scrollTarget={guid}
        scrollable
        size={30}
        onNoResults={t`Aucun résultat trouvé`}
        onResultStats={nbResults => (
          <small id="stats">
            {t`${nbResults} résultats trouvés`}
          </small>
        )}
        loader={getLoader()}
        {...props}
        innerClass={{
          image: 'product-image',
          title: 'product-title',
          listItem: 'catalog-item',
          resultsInfo: 'meta-info',
          ...innerClass,
        }}
      />
    </div>
  );
};

export default ResultList;
