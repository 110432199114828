/* eslint react/prefer-stateless-function:0 */
/* eslint no-console:0 */
/* eslint max-len:0 */
/* eslint camelcase:0 */
import React, { Component } from 'react';
import PropTypes from 'prop-types';
import {
  ReactiveBase, DataSearch, ReactiveComponent,
} from '@appbaseio/reactivesearch';
import { t } from 'ttag';
import StatisticsContent from '../ui/StatisticsContent';
import { getSiteAggregationQuery } from '../../helper/es-queries';
import { getLoader } from '../../helper/utils';
import callApi from '../../helper/api-fetch';

class SiteStatistics extends Component {
  constructor(props) {
    super(props);
    this.state = {
      siteInfoLoaded: false,
      nbMatchingLoaded: false,
      competitorData: [],
      siteName: null,
    };
    this.handleValueSelected = this.handleValueSelected.bind(this);
  }

  componentDidMount() {
    const { match } = this.props;
    if (match.params.site_id) {
      this.fetchSiteInfo(match.params.site_id);
    }
  }

  getMatchingNb(competitorIds) {
    const promises = competitorIds.map(siteId => callApi({
      path: `/sites/${siteId}/matchingnb`,
      errorMessages: {
        404: t`Le site indiqué n'existe pas`,
      },
      onSuccess: (data) => {
        const dataItem = { id_si: siteId, nbMatching: parseInt(data.nbMatching, 10) };
        this.setState(prevState => ({
          competitorData: [dataItem, ...prevState.competitorData],
        }));
      },
      onError: error => console.log(error.message),
    }));

    Promise.all(promises).then(
      () => this.setState({ nbMatchingLoaded: true }),
    );
  }

  fetchSiteInfo(siteId) {
    this.setState({ siteInfoLoaded: false });

    callApi({
      path: `/sites/${siteId}`,
      errorMessages: {
        404: t`Le site indiqué n'existe pas`,
      },
      onSuccess: (data) => {
        this.setState({
          siteInfoLoaded: true,
          siteName: data.name,
        });
        this.getMatchingNb([parseInt(siteId, 10), ...data.concurrentIds.map(strInt => parseInt(strInt, 10))]);
      },
      onError: (error) => {
        console.log(error.message);
      },
    });
  }

  handleValueSelected(value, cause, source) {
    if (!source) {
      return;
    }
    const siteId = source.id_si;
    this.fetchSiteInfo(siteId);
  }

  render() {
    const {
      siteInfoLoaded,
      competitorData,
      siteName,
      nbMatchingLoaded,
    } = this.state;
    return (
      <ReactiveBase
        app={process.env.REACT_APP_ELASTIC_INDEX}
        url={process.env.REACT_APP_ELASTIC_ENDPOINT}
        credentials={process.env.REACT_APP_PROXY_CREDENTIALS}
        className="sitestatistics"
      >
        <DataSearch
          componentId="SearchSite"
          dataField={['nom_si']}
          placeholder="Nom du site"
          autosuggest
          highlight
          queryFormat="and"
          debounce={100}
          fuzziness="AUTO"
          onValueSelected={this.handleValueSelected}
          defaultSelected={siteName}
        />
        {(!siteInfoLoaded || !nbMatchingLoaded) && getLoader() }
        {siteInfoLoaded && nbMatchingLoaded && (
          <ReactiveComponent
            componentId="aggregator"
            defaultQuery={() => getSiteAggregationQuery(competitorData.map(item => item.id_si))}
          >
            <StatisticsContent competitorData={competitorData} />
          </ReactiveComponent>
        )}
      </ReactiveBase>
    );
  }
}

SiteStatistics.propTypes = {
  match: PropTypes.shape({
    params: PropTypes.shape({
      site_id: PropTypes.string.isRequired,
    }),
  }).isRequired,
};

export default SiteStatistics;
