import React from 'react';
import PropTypes from 'prop-types';
import styled from '@emotion/styled';

const Img = styled('img')`
  ${p => (p.height ? `height: ${p.height};` : '')}
  ${p => (p.width ? `width: ${p.width};` : '')}
  position: relative;
  top: 50%;
  transform: translateY(-50%);
`;

const ImgMini = styled('img')`
  position: relative;
  top: 50%;
  transform: translateY(-50%);
  max-height  : ${props => props.maxHeight ? props.maxHeight : ""};
  max-width : ${props => props.maxWidth ? props.maxWidth : ""};
`;
const Image = ({ src, alt, ...props }) => <Img src={src} alt={alt} {...props} />;

Image.propTypes = {
  src: PropTypes.string.isRequired,
  alt: PropTypes.string,
  height: PropTypes.string,
  width: PropTypes.string,
};

export const ImageMini = ({ src, alt, ...props }) => <ImgMini src={src} alt={alt} {...props} />;

ImageMini.propTypes = {
  src: PropTypes.string.isRequired,
  alt: PropTypes.string,
  height: PropTypes.string,
  width: PropTypes.string,
};

Image.defaultProps = {
  alt: '',
};

export default Image;
