import React, { Component } from 'react';
import PropTypes from 'prop-types';
import '../../styles/SiteStatistics.css';
import { getLoader } from '../../helper/utils';

class StatisticsContent extends Component {
  static makePercentTag(subBucketCount, bucketCount) {
    const colorList = [
      { color: 'Chartreuse', value: [70, 100] },
      { color: 'Gold', value: [10, 69] },
      { color: 'Red', value: [0, 9] },
    ];
    const percent = Math.min(Math.round(subBucketCount * 100 / bucketCount), 100);

    const { color } = colorList.filter(colorItem => percent <= Math.max(...colorItem.value)
      && percent >= Math.min(...colorItem.value)).shift();

    return (
      <b style={{ color }}>
        {`${percent} %`}
      </b>
    );
  }

  generateConfig() {
    const { competitorData } = this.props;
    const baseConfig = [
      {
        header: 'ID',
        label: bucket => bucket.key,
      },
      {
        header: 'Site',
        label: bucket => bucket.nom_si.buckets[0].key,
        condition: bucket => bucket.nom_si.buckets.length > 0,
      },
      {
        header: 'Produits',
        label: bucket => bucket.doc_count,
      },
      {
        header: 'Crawl global 24H',
        docCount: bucket => bucket.date_last_crawl.buckets[0].doc_count,
      },
      {
        header: 'Matching',
        label: bucket => competitorData
          .filter(competitor => competitor.id_si === bucket.key)[0].nbMatching,
      },
      {
        header: 'Prix no null',
        docCount: bucket => bucket.prix_prod.sum_other_doc_count,
      },
      {
        header: 'Reference',
        docCount: bucket => bucket.reference.sum_other_doc_count,
      },
      {
        header: 'Ref Fabriquant',
        docCount: bucket => bucket.ref_fabriquant.sum_other_doc_count,
      },
      {
        header: 'Ref Fournisseur',
        docCount: bucket => bucket.ref_fournisseur.sum_other_doc_count,
      },
      {
        header: 'EAN',
        docCount: bucket => bucket.ean.sum_other_doc_count,
      },
      {
        header: 'Marque',
        docCount: bucket => bucket.marque.sum_other_doc_count,
      },
      {
        header: 'Détail',
        label: bucket => bucket.marque.buckets.map(marqueItem => (
          `<${marqueItem.key} : ${marqueItem.doc_count}> `
        )),
        className: 'allowWrap',
      },
    ];

    const defaultConfigItem = { condition: () => true };
    return baseConfig.map(configItem => ({ ...configItem, ...defaultConfigItem }));
  }

  render() {
    const { aggregations } = this.props;
    if (!aggregations) {
      return getLoader();
    }

    const { buckets } = aggregations.meta_aggs;
    const rowConfig = this.generateConfig();
    return (
      <table style={{ margin: 'auto' }} cellSpacing={0} cellPadding={0}>
        <thead>
          <tr>
            {rowConfig.map(config => (
              <th key={config.header}>
                {config.header}
              </th>
            ))}
          </tr>
        </thead>
        <tbody>
          {buckets.map(bucket => (
            <React.Fragment key={bucket.key}>
              <tr className="firstLine" key={`first${bucket.key}`}>
                {rowConfig.map((config) => {
                  const rowSpan = config.docCount ? 1 : 2;
                  const value = config.docCount
                    ? this.constructor.makePercentTag(config.docCount(bucket), bucket.doc_count)
                    : config.label(bucket);
                  return (
                    <td key={config.header} rowSpan={rowSpan} className={config.className}>
                      {config.condition(bucket) && value}
                    </td>
                  );
                })}
              </tr>
              <tr className="secondLine" key={`second${bucket.key}`}>
                {rowConfig.filter(config => config.docCount).map(config => (
                  <td key={config.header} className={config.className}>
                    {config.condition(bucket) && config.docCount(bucket)}
                  </td>
                ))}
              </tr>
            </React.Fragment>
          ))}
        </tbody>
      </table>
    );
  }
}

StatisticsContent.defaultProps = {
  aggregations: null,
};
StatisticsContent.propTypes = {
  aggregations: PropTypes.shape({
    meta_aggs: PropTypes.shape({
      buckets: PropTypes.array,
    }),
  }),
  competitorData: PropTypes.instanceOf(Array).isRequired,
};
export default StatisticsContent;
