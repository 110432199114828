/* eslint no-underscore-dangle: 0 */
/* eslint no-unused-expressions: [2, { allowShortCircuit: true }] */
import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { t } from 'ttag';
import {
  ReactiveBase,
  SelectedFilters,
  SingleDropdownList,
} from '@appbaseio/reactivesearch';
import { Switch } from '@material-ui/core';
import ResultList from '../result/ResultList';
import {
  getImagePath, getModesEnum, makeSpanLink, Reference, getLoader, getSortOptions,
} from '../../helper/utils';
import '../../styles/Catalog.css';
import { WebsiteController, FilterMachingByIdsProduct, ProductAtiveController, ProductFavoritesController } from '../datacontroller/controllers';
import ReactDataSearch from '../reactivesearch/ReactDataSearch';
import callApi from '../../helper/api-fetch';

class Catalog extends Component {
  constructor(props) {
    super(props);

    this.state = {
      hideMatchings: false,
      hasMarques: false,
      isLoaded: false,
      isVisible: true,
      currentValue: null,
      hideResultList: false,
      idsProductsMatching: [],
      isFavorites: false,
    };
    this.currentTimer = null;
    this.toggleHideMatchings = this.toggleHideMatchings.bind(this);
    this.dataSearcheonValueChange = this.dataSearcheonValueChange.bind(this);
    this.toggleFilterProductsFavorite = this.toggleFilterProductsFavorite.bind(this);
  }

  componentDidMount() {
    const {
      websiteId, initProductId, selectProductHandler,
    } = this.props;
    const productDocumentUrl = `${process.env.REACT_APP_ELASTIC_ENDPOINT}/${process.env.REACT_APP_ELASTIC_INDEX}/doc/${initProductId}`;
    const headers = new Headers();
    headers.append('Authorization', `Basic ${btoa(process.env.REACT_APP_PROXY_CREDENTIALS)}`);
    headers.append('Content-Type', 'application/json');
    const productPromise = initProductId
      ? fetch(productDocumentUrl, { headers })
        .then(res => res.json())
        .then((json) => {
          const {
            _source: {
              id_si: productWebsiteIdStr,
            },
          } = json;
          websiteId === parseInt(productWebsiteIdStr, 10) && (() => {
            selectProductHandler(json._source);
            this.setState({ isVisible: false });
          })();
        }) : Promise.resolve();
    Promise.all([productPromise]).then(() => this.setState({ isLoaded: true }));
  }

  static getMatchingBullet(nbMatching) {
    if (nbMatching === 0) {
      return null;
    }
    if (typeof (nbMatching) === 'undefined') {
      return (
        <span className="nbMatchingBullet undefined">
          {'?'}
        </span>
      );
    }

    return (
      <span className="nbMatchingBullet">
        {nbMatching}
      </span>
    );
  }

  toggleFilterProductsFavorite() {
    console.log("toggleFilterProductsFavorite");
    this.setState({
      hideResultList: true,
    });
    this.setState(
      prevState => ({
        isFavorites: !prevState.isFavorites,
        hideResultList: false,
      }),
    );
  }

  toggleHideMatchings() {
    const { hideMatchings } = this.state;
    const { notifHandler, websiteId, initBoutiqueId } = this.props;
    const idSiteCompetitor = initBoutiqueId || 0;
    this.setState(
      prevState => ({
        hideMatchings: !prevState.hideMatchings,
      }),
    );
    if (!hideMatchings) {
      this.setState({ hideResultList: true });
      callApi({
        path: `/sites/${websiteId}/matching-competitor/true/${idSiteCompetitor}/?isBestPrice=false`,
        onSuccess: (dataMatching) => {
          const idsProductCompetitor = [];
          Object.values(dataMatching.produitsRels).map(idProd => Array.prototype.push.apply(idsProductCompetitor, idProd.id_prod_concu));
          const idsProductClient = Object.keys(dataMatching.produitsRels).map(idProd => parseInt(idProd, 10));
          this.setState({
            idsProductsMatching: idsProductClient.concat(idsProductCompetitor.map(idProd => parseInt(idProd, 10))),
            hideResultList: false,
          });
        },
        onError: error => notifHandler(error.message),
      });
    }
  }

  dataSearcheonValueChange(value) {
    this.setState(
      () => ({ currentValue: value, hideResultList: true }),
      () => {
        if (this.currentTimer) {
          clearTimeout(this.currentTimer);
          this.currentTimer = null;
        }
        this.currentTimer = setTimeout(() => {
          this.setState({ hideResultList: false });
        }, 200);
      },
    );
  }

  render() {
    const { isLoaded, isVisible, hideMatchings, hideResultList, hasMarques, currentValue, idsProductsMatching, isFavorites } = this.state;
    const { websiteId, mode, selectProductHandler } = this.props;

    const searchComponents = ['websiteFilter', 'productAtiveController', 'searchInput', 'catalogMarqueFilter',
      ...hideMatchings ? ['hideMatchingsFilter'] : [], ...isFavorites ? ['filterProductFavorites'] : []];

    return isLoaded && isVisible && (
      <ReactiveBase
        app={process.env.REACT_APP_ELASTIC_INDEX}
        url={process.env.REACT_APP_ELASTIC_ENDPOINT}
        credentials={process.env.REACT_APP_PROXY_CREDENTIALS}
        className="catalog"
      >

        <WebsiteController websiteId={websiteId} componentId="websiteFilter" />
        {hideMatchings && (
        <FilterMachingByIdsProduct websiteId={websiteId} componentId="hideMatchingsFilter" idsProduct={idsProductsMatching} />
        )}
        <ProductAtiveController siteId={websiteId} componentId="productAtiveController" />
        { isFavorites && <ProductFavoritesController siteId={websiteId} componentId="filterProductFavorites" />}
        <div className="header-search">
          <ReactDataSearch
            componentId="searchInput"
            dataField={['nom_prod', 'url_prod', 'reference', 'ref_fabriquant', 'ref_fournisseur', 'ean']}
            reactDependent={['websiteFilter']}
            placeholder={t`Rechercher parmi vos produits`}
            onValueChange={this.dataSearcheonValueChange}
          />
          <SelectedFilters className="selected-filters" showClearAll={false} />
        </div>

        <SingleDropdownList
          componentId="catalogMarqueFilter"
          dataField="nom_marque.keyword"
          placeholder={t`Filtrer par marque`}
          filterLabel="marque"
          showMissing={false}
          showSearch
          react={{
            and: ['websiteFilter', 'searchInput', 'productAtiveController'],
          }}
          className="marqueFilter"
          innerClass={{ select: 'select', list: 'list' }}
          URLParams
        />

        {hideResultList && (
          <div style={{ flex: 1, paddingTop: '30px' }}>
            {getLoader()}
          </div>
        )}
        {!hideResultList && (
        <ResultList
          react={{
            and: searchComponents,
          }}
          dataField="nom_prod.keyword"
          sortOptions={getSortOptions(hasMarques, currentValue)}
          showResultStats
          onNoResults="Aucun résultat trouvé"
          innerClass={{ sortOptions: 'sortOptions' }}
          onData={res => ({
            containerProps: {
              onClick: (e) => {
                selectProductHandler(res);
                [...document.querySelectorAll('.catalog-item')].forEach(elem => elem.classList.remove('active'));
                e.currentTarget.classList.add('active');
              },
            },
            image: getImagePath(res.id_si, res.image_nom_prod),
            image_size: 'small',
            title: (<span dangerouslySetInnerHTML={{ __html: `<span>${res.nom_prod}</span>` }} />),
            description: (
              <React.Fragment>
                <Reference
                  value={[res.reference, res.ean, res.ref_fabriquant, res.ref_fournisseur]}
                />
                <span style={{ display: 'flex', justifyContent: 'space-between' }}>
                  {makeSpanLink(t`Voir la page`, res.url_prod, {
                    className: 'seePageLink', 'data-product_id': res.id_prod,
                  })}
                  {this.constructor.getMatchingBullet(res.nb_matching)}
                </span>
              </React.Fragment>
            ),
          })}
        />)}

        <div className="hideMatchings">
          <div className="labelSwitch">{t`Masquer les produits déjà associés`}</div>
          <Switch
            checked={hideMatchings}
            onChange={this.toggleHideMatchings}
            color="primary"
            classes={{ root: 'switchRoot' }}
          />
        </div>
        <div className="hideMatchings" style={{ border: 'solid 0.1em #2a2a2a', borderTopColor: '#E1E1E1' }}>
          <div className="labelSwitch">
            {t`Afficher uniquement les produits favoris`}
          </div>
          <Switch
            checked={isFavorites}
            onChange={() => { this.toggleFilterProductsFavorite() }}
            color="primary"
            classes={{ root: 'switchRoot' }}
          />
        </div>
        {mode === getModesEnum().marque && (
        <div className="importProducts">
          {makeSpanLink(t`Ajouter / Modifier des produits`, '#', {
            className: 'button__orange',
            onClick: () => {
              const message = { security: "DON'T PANIC", action: 'importProduct', data: { websiteId } };
              window.parent.postMessage(message, '*');
              return false;
            },
          })}
        </div>
        )}
      </ReactiveBase>
    );
  }
}

Catalog.defaultProps = {
  initProductId: null,
  initBoutiqueId: null,
  notifHandler: () => {},

};

Catalog.propTypes = {
  websiteId: PropTypes.number.isRequired,
  initProductId: PropTypes.number,
  selectProductHandler: PropTypes.func.isRequired,
  mode: PropTypes.number.isRequired,
  initBoutiqueId: PropTypes.number,
  notifHandler: PropTypes.func,
};


export default Catalog;
