/* eslint no-underscore-dangle: 0 */
import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { t } from 'ttag';
import {
  ReactiveBase,
  SelectedFilters,
} from '@appbaseio/reactivesearch';
import { Button } from '@material-ui/core';
import ResultList from '../result/ResultList';
import CursorDataSearch from '../search/CursorDataSearch';
import '../../styles/CompetitorProducts.css';
import generateResultItem from '../result/generateResultItem';
import Filter from '../list/Filter';
import { CompetitorsController, ReferenceController } from '../datacontroller/controllers';
import callApi from '../../helper/api-fetch';

class CompetitorProducts extends Component {
  constructor(props) {
    super(props);
    this.state = {
      matchedProductIds: [],
      dataRetrieved: false,
      initValue: '',
      prevProductId: null,
    };

    this.updateMatchedProducts = this.updateMatchedProducts.bind(this);
  }

  componentDidMount() {
    const { product } = this.props;
    this.loadProductData(product);
  }

  componentDidUpdate() {
    const { dataRetrieved, apiAvailable } = this.state;
    const { product } = this.props;
    if (apiAvailable && !dataRetrieved) {
      this.loadProductData(product);
    }
  }

  /**
   *  Excellent article about async data updates on props change:
   * https://reactjs.org/blog/2018/03/27/update-on-async-rendering.html
   *
   */
  static getDerivedStateFromProps(newProps, prevState) {
    const { id_prod: newProductId } = newProps.product ? newProps.product : {};
    const { prevProductId } = prevState;
    if (newProps.product && newProductId !== prevProductId) {
      return {
        prevProductId: newProductId,
        matchedProductIds: [],
        dataRetrieved: false,
        apiAvailable: true,
        initValue: '',
      };
    }

    return null;
  }

  getFiltersConfig() {
    const innerClass = {
      list: 'list scrollable', label: 'label', checkbox: 'checkbox', title: 'title', count: 'count', input: 'input',
    };
    const { competitorIds } = this.props;
    const filters = [{
      componentId: 'marqueFilter',
      dataField: 'nom_marque.keyword',
      title: t`Marques`,
      filterLabel: t`marque`,
      placeholder: t`Rechercher une marque`,
      andFilterComponents: ['competitorFilter'],
      orFilterComponents: ['mainSearch'],
      innerClass,
      showMissing: false,
      showCount: false,
      transformData: items => items.filter(item => [
        'aucune',
        'n/a',
        'null',
        'false',
        'pas',
        '0',
        'no',
      ].indexOf(item.key.toLowerCase()) === -1),
    }];

    if (competitorIds.length > 1) {
      filters.push({
        componentId: 'boutiqueFilter',
        dataField: 'nom_si.keyword',
        title: t`Boutiques`,
        selectAllLabel: t`Toutes les boutiques`,
        placeholder: t`Rechercher une boutique`,
        filterLabel: t`boutique`,
        andFilterComponents: ['competitorFilter'],
        orFilterComponents: ['mainSearch', 'marqueFilter'],
        innerClass,
      });
    }
    if (filters.length === 1) {
      filters[0].innerClass.list += ' only-list';
    }
    return filters;
  }

  loadProductData(product) {
    const { id_prod: productId } = product;

    const { notifHandler } = this.props;

    callApi({
      path: `/produits/${productId}`,
      errorMessages: {
        404: t`Le produit demandé n'existe plus`,
      },
      onSuccess: (data) => {
        const { matchedProductIds, name } = data;
        this.setState({
          dataRetrieved: true,
          initValue: name,
          matchedProductIds: matchedProductIds.map(idStr => parseInt(idStr, 10)),
        });
      },
      onError: (error) => {
        this.setState({
          apiAvailable: false,
        });
        notifHandler(error.message);
      },
    });
  }

  updateMatchedProducts(productIdList) {
    this.setState({
      matchedProductIds: productIdList.map(idStr => parseInt(idStr, 10)),
    });
  }

  render() {
    const { initValue, dataRetrieved, matchedProductIds } = this.state;
    const {
      competitorIds, notifHandler, tabSwitchHandler,
      currentFilters, onFilterValueChange, product, websiteStates,
    } = this.props;
    const { id_prod: productId } = product;

    return (
      <ReactiveBase
        app={process.env.REACT_APP_ELASTIC_INDEX}
        url={process.env.REACT_APP_ELASTIC_ENDPOINT}
        credentials={process.env.REACT_APP_PROXY_CREDENTIALS}
        className="search-matching"
      >
        <CompetitorsController competitorIds={competitorIds} />
        <ReferenceController product={product} />

        {dataRetrieved && (
          <React.Fragment>

            <div className="header-search">
              <div className="search">
                <div className="switch-tab">
                  <Button size="small" onClick={() => tabSwitchHandler(1)}>
                    {`+ ${t`Ajouter par URL`}`}
                  </Button>
                </div>

                <CursorDataSearch
                  matchedProductIds={matchedProductIds}
                  initValue={initValue}
                  marques={currentFilters.marqueFilter}
                />
              </div>
            </div>

            <div className="matching-body">
              <ResultList
                dataField="nom_prod"
                showResultStats
                react={{
                  and: ['competitorFilter', 'boutiqueFilter'],
                  or: ['mainSearch', 'matchReferences'],
                }}
                onData={res => generateResultItem(
                  productId, res, matchedProductIds, notifHandler, this.updateMatchedProducts, websiteStates,
                )}
              />

              <div className="filters">
                <SelectedFilters className="selected-filters" showClearAll={false} />
                {this.getFiltersConfig().map(
                  config => (
                    <Filter
                      key={config.componentId}
                      defaultSelected={config.componentId in currentFilters
                        ? currentFilters[config.componentId] : []}
                      onValueChange={(value) => {
                        onFilterValueChange(config.componentId, value);
                      }}
                      {...config}
                    />
                  ),
                )}
              </div>
            </div>
          </React.Fragment>
        )}
      </ReactiveBase>
    );
  }
}

CompetitorProducts.defaultProps = {
  notifHandler: () => {},
  onFilterValueChange: () => {},
  currentFilters: {},
};

CompetitorProducts.propTypes = {
  product: PropTypes.shape({
    id_prod: PropTypes.number.isRequired,
  }).isRequired,
  notifHandler: PropTypes.func,
  competitorIds: PropTypes.arrayOf(PropTypes.number).isRequired,
  // eslint-disable-next-line react/forbid-prop-types
  websiteStates: PropTypes.object.isRequired,
  tabSwitchHandler: PropTypes.func.isRequired,
  currentFilters: PropTypes.objectOf(PropTypes.arrayOf(PropTypes.string)),
  onFilterValueChange: PropTypes.func,
};

export default CompetitorProducts;
