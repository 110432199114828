import { t } from 'ttag';
const callApi = (configuration) => {
  let cookieValueBearer = null;
  if(document.cookie){
    cookieValueBearer = document.cookie.split('; ').find(row => row.startsWith('BEARER')).split('=')[1]; 
  }
  const defaultConfiguration = {
    method: 'GET',
    errorMessages: {
      401: t`Vous êtes déconnecté. Veuillez recharger la page.`,
      403: t`Vous n'êtes pas le propriétaire de ce compte.`,
      404: t`Les données demandées n'existent pas`,
    },
  };

  
  const {
    method, path, onSuccess, onError,
  } = { ...defaultConfiguration, ...configuration };

  const errorMessages = { ...defaultConfiguration.errorMessages, ...configuration.errorMessages };
  const url = process.env.REACT_APP_PRIXAN_API_ENDPOINT + path;
  const urlParams = new URLSearchParams(window.location.search);
  const token = urlParams.get('token');
  let  headersResquest = new Headers({ 'Accept': 'application/json'});
  if(token){
    headersResquest.append('Authorization', 'BEARER ' + token );
  }
  if(!token  &&  cookieValueBearer){
    headersResquest.append('Authorization', 'BEARER ' + cookieValueBearer );
  }
  return fetch(url, { credentials: 'include', method, headers:  headersResquest })
    .then((response) => {
      if (response.status === 200) {
        return response.json();
      }

      const errorMessage = Object.prototype.hasOwnProperty.call(errorMessages, response.status)
        ? errorMessages[response.status] : t`Une erreur est survenue`;

      return Promise.reject(new Error(errorMessage));
    }, () => Promise.reject(new Error(t`Une erreur est survenue`)))
    .then(onSuccess)
    .catch(onError);
};

export default callApi;

export const myFetch = async (configuration) => {
  const defaultConfiguration = {
    method: 'GET',
    headers: new Headers({ Accept: 'application/json' }),
    // body: '',
    errorMessages: {
      401: t`Vous êtes déconnecté. Veuillez recharger la page.`,
      403: t`Vous n'êtes pas le propriétaire de ce compte.`,
      404: t`Les données demandées n'existent pas`,
    },
  };

  const {
    url, method, headers, body, credentials,
  } = {
    ...defaultConfiguration,
    ...configuration,
  };

  const errorMessages = { ...defaultConfiguration.errorMessages, ...configuration.errorMessages };

  let data = {};

  try {
    const response = await fetch(url, {
      credentials, method, headers, body,
    });
    const errorMessage = Object.prototype.hasOwnProperty.call(errorMessages, response.status)
      ? errorMessages[response.status]
      : t`Une erreur est survenue`;
    switch (response.status) {
      case 200:
        data = await response.json();
        break;
      default:
        // throw new Error(errorMessage);
        console.log(errorMessage, response, url, body);
    }
  } catch (err) {
    console.log(err.message, url);
  }
  // console.log('myFetch data', url, data);
  return data;
};

export const callElastic = async function (query) {
  // const scheme = process.env.REACT_APP_ELASTIC_ENDPOINT_SCHEME;
  // const domain = process.env.REACT_APP_ELASTIC_ENDPOINT_DOMAIN;
  // const url = `${scheme}://${domain}/${process.env.REACT_APP_ELASTIC_INDEX}/_search`;
  const url=`${process.env.REACT_APP_ELASTIC_ENDPOINT}/${process.env.REACT_APP_ELASTIC_INDEX}/_search`;
  const headers = new Headers();
  headers.append('Authorization', `Basic ${btoa(process.env.REACT_APP_PROXY_CREDENTIALS)}`);
  headers.append('Content-Type', 'application/json');

  const res = await myFetch({
    url,
    method: 'POST',
    headers,
    body: JSON.stringify(query),
    credentials: 'same-origin',
  });
  const data = res && res.hits ? res.hits.hits.map(hit => hit._source) : [];
  return data;
};

