import {
  addLocale,
} from 'ttag';
import { getCookie } from './utils';

export function getLang() {
  return getCookie('_icl_current_language');
}

export function setLocaletranslation(locale) {

  if (locale !== undefined && locale !== 'fr') {
    const translationObj = require(`../../i18nPo/${locale}.po.json`);
    addLocale(locale, translationObj);
    // useLocale(locale);
  }
}
