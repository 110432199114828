import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { t } from 'ttag';
import qs from 'qs';
import {
  ReactiveBase, DataSearch, ResultList, MultiList,
} from '@appbaseio/reactivesearch';
import styled from 'react-emotion';
import Header from '../ui/Header';
import { getImagePath, sortOptionsDateCrawl, stripTags } from '../../helper/utils';
import '../../styles/Demo.css';
import { CompetitorsController } from '../datacontroller/controllers';

class DemoSites extends Component {
  constructor(props) {
    super(props);
    const { location } = props;
    const parsed = qs.parse(location.search.replace('?', ''));
    this.state = {
      competitorsIdDefault: parsed.ids ? parsed.ids : '28,60735,29',
    };
  }

  render() {
    const { competitorsIdDefault } = this.state;
    return (
      <ReactiveBase
        app={process.env.REACT_APP_ELASTIC_INDEX}
        url={process.env.REACT_APP_ELASTIC_ENDPOINT}
        credentials={process.env.REACT_APP_PROXY_CREDENTIALS}
      >
        <CompetitorsController competitorIds={competitorsIdDefault ? competitorsIdDefault.split(',') : []} />
        <Header color="black" className="demo" sticky>
          <React.Fragment>
            <DataSearch
              className="mainSearch"
              componentId="mainSearch"
              placeholder={t`Nom du produit`}
              queryFormat="or"
              highlight
              fuzziness="AUTO"
              debounce={400}
              autosuggest={false}
              dataField={['nom_prod', 'url_prod']}
            />
            <a
              className="subscribe"
              href="https://app.prixan.com/fr/login"
              target="_blank"
              rel="noopener noreferrer"
            >
              {`>>${t`inscription`}`}
            </a>
          </React.Fragment>
        </Header>

        <div id="page">
          <ResultList
            className="results"
            componentId="results"
            dataField="nom_prod"
            sortOptions={sortOptionsDateCrawl()}
            react={{
              and: ['mainSearch', 'brandFilter', 'siteFilter', 'competitorFilter'],
            }}
            size={24}
            innerClass={{
              image: 'product-img',
              listItem: 'product-item',
              list: 'products',
              resultsInfo: 'search-stats',
              sortOptions: 'sortOptions',
            }}
            onResultStats={(nbResults, timeTaken) => (
              <small>
                {t`${nbResults} résultats en ${timeTaken} ms`}
              </small>
            )}
            onData={(res) => {
              let dateLastCrawlStr;
              if (res.date_last_crawl) {
                const d = new Date(res.date_last_crawl);
                dateLastCrawlStr = d.toLocaleDateString([], {
                  year: 'numeric', month: 'long', day: '2-digit', hour: 'numeric', minute: 'numeric', second: 'numeric',
                });
              } else {
                dateLastCrawlStr = 'N/A';
              }
              return {
                image: getImagePath(res.id_si, res.image_nom_prod),
                title: res.nom_prod,
                url: res.url_prod ? stripTags(res.url_prod) : '',
                description: (
                  <div data-product_id={res.id_prod}>
                    <i
                      style={{
                        display: 'block',
                        textOverflow: 'ellipsis',
                        overflow: 'hidden',
                        whiteSpace: 'nowrap',
                        margin: '8px 0',
                        color: 'rgb(0,0,238)',
                        cursor: 'pointer',
                        textDecoration: 'underline',
                      }}
                      dangerouslySetInnerHTML={{ __html: res.url_prod }}
                    />
                    <TableLike>
                      <div>
                        {res.prix_prod > 0
                      && (
                      <p className="price">
                        {`${res.prix_prod.toLocaleString()} €`}
                      </p>
                      )
                    }
                        { (parseInt(res.prix_prod, 10) === 0 || Number.isNaN(parseInt(res.prix_prod, 10))) && (
                        <p>
                          {`Code HTTP: ${res.http_code}`}
                        </p>
                        )
                    }
                        <p>
                          {dateLastCrawlStr}
                        </p>
                      </div>
                      <section>
                        { res.nom_marque
                    && (
                    <p>
                      <span>
                        {'Marque:'}
                      </span>
                      <b>
                        {res.nom_marque}
                      </b>
                    </p>
                    )}
                        { res.ean
                      && (
                      <p>
                        <span>
                          {'EAN:'}
                        </span>
                        <b>
                          {res.ean}
                        </b>
                      </p>
                      )}
                        { res.reference
                      && (
                      <p>
                        <span>
                          {'Référence:'}
                        </span>
                        <b>
                          {res.reference}
                        </b>
                      </p>
                      )}
                        { res.ref_fabriquant
                      && (
                      <p>
                        <span>
                          {'ref_fabriquant:'}
                        </span>
                        <b>
                          {res.ref_fabriquant}
                        </b>
                      </p>
                      ) }
                        { res.ref_fournisseur
                      && (
                      <p>
                        <span>
                          {'ref_fournisseur:'}
                        </span>
                        <b>
                          {res.ref_fournisseur}
                        </b>
                      </p>
                      ) }
                        { res.nb_matching > 0
                      && (
                      <p>
                        <span>
                          {'Matchings:'}
                        </span>
                        <b>
                          {res.nb_matching}
                        </b>
                      </p>
                      ) }
                      </section>
                    </TableLike>
                  </div>
                ),
              };
            }}
          />

          <div id="filters">
            <div className="sticky">
              <MultiList
                componentId="brandFilter"
                dataField="nom_marque.keyword"
                react={{
                  and: ['mainSearch', 'siteFilter', 'competitorFilter'],
                }}
                title={t`Marque`}
              />
              <MultiList
                componentId="siteFilter"
                dataField="nom_si.keyword"
                react={{
                  and: ['mainSearch', 'brandFilter', 'competitorFilter'],
                }}
                title="Webshop"
              />
            </div>
          </div>
        </div>

      </ReactiveBase>
    );
  }
}

const TableLike = styled('div')`
  display: flex;
  flex-direction: row-reverse;
  justify-content: space-between;

  > div {
    text-align: right;
    align-self: flex-end;
  }
  .price {
    font-size: 1.5em;
    font-weight: bold;
  }

  section {
    display: table;
    p {
      display: table-row;
      span,b {
        display: table-cell;
      }
      span {
        padding-right: 1.2em;
      }
    }
  }

`;
DemoSites.propTypes = {
  location: PropTypes.shape({
    search: PropTypes.string,
  }).isRequired,
};
export default DemoSites;
