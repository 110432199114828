/* eslint-disable import/no-named-as-default */
/* eslint-disable no-unused-expressions */
/* eslint-disable react/destructuring-assignment */
import React, { Component } from 'react';
import PropTypes from 'prop-types';
import {
  Paper, Tooltip, Grid,
} from '@material-ui/core';
import InfoIcon from '@material-ui/icons/Info';
import { t } from 'ttag';
import styled from '@emotion/styled';
import ItemProduct from './ItemProduct';
import IOSSwitch from '../Switch/IOSSwitch';
import { getLoader } from '../../../helper/utils';
import '../../../styles/Marker.css';
const VFlex = styled('div')`
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  flex-grow: 2;
  align-items: center;
`;

class ItemSuggestion extends Component {
  constructor(props) {
    super(props);
    this.state = {
      productsSuggestion: [],
      isLoad: false,
      fromElastic: 0,
    };
    this.checkMatching = this.checkMatching.bind(this);
    this.handAutorenew = this.handAutorenew.bind(this);
  }

  async componentDidMount() {
    this.getSuggestion();
  }

  async getSuggestion() {
    const { fromElastic } = this.state;
    this.setState({ productsSuggestion: await this.props.getSuggestionElastic(fromElastic) },
      () => {
        const idProdCompetitor = this.state.productsSuggestion.length ? this.state.productsSuggestion[0].id_prod : 0;
        this.setState({ isLoad: true });
        this.props.updateMatchingsToAdd(this.props.product.id_prod, idProdCompetitor, true);
      });
  }

  checkMatching(isChecked) {
    const { product } = this.props;
    const { productsSuggestion } = this.state;
    productsSuggestion.length && this.props.updateMatchingsToAdd(product.id_prod, productsSuggestion[0].id_prod, isChecked);
  }

  handAutorenew() {
    this.setState(prevState => ({ fromElastic: prevState.fromElastic + 1 }), () => { this.checkMatching(false); this.getSuggestion(); });
  }

  render() {
    const {
      product,
    } = this.props;
    const { productsSuggestion, isLoad } = this.state;
    return (
      <Grid container spacing={1} style={{ padding: '5px 20px 0px 20px', borderStyle: 'solid',  borderColor: '#dddddd', borderWidth: '0px 1px 1px 0px' }}>
        <Grid item xs={5} style={{ padding: '1px' }} >
          <ItemProduct
            product={product}
            key={`iprod${product.id_prod}`}
          />
        </Grid>
        <Grid item xs={2} container alignItems="center" justify="center" >
          {productsSuggestion.length !== 0 && (
          <VFlex>
            <Tooltip title={t`Associer ce produit`} placement="top">
              <IOSSwitch onChangeSwitch={this.checkMatching} />
            </Tooltip>
          </VFlex>
          )}
        </Grid>
        <Grid item xs={5}>
          {!isLoad && (
          <div style={{ flex: 1, paddingTop: '30px' }}>
            {getLoader()}
          </div>
          )}
          {productsSuggestion.length !== 0 && productsSuggestion.map(productCompetitor => (
            <ItemProduct
              product={productCompetitor}
              key={`iprod_${productCompetitor.id_prod}`}
              isProductClient={false}
              onclickAutorenew={this.handAutorenew}
            />
          ))}
          {productsSuggestion.length === 0 && isLoad
          && (

            <Paper style={{ height: '100%' }}>
              <div style={{
                textAlign: 'center', fontSize: '18px', height: '100%', color: '#cecece', verticalAlign: 'middle', display: 'table-cell',
              }}
              >
                <InfoIcon />
                <span>{t`Pas de suggestion`}</span>
              </div>
            </Paper>
          )}
        </Grid>
      </Grid>
    );
  }
}
ItemSuggestion.propTypes = {
  product: PropTypes.shape({
    id_si: PropTypes.number.isRequired,
    image_nom_prod: PropTypes.string,
    id_prod: PropTypes.number,
    nom_prod: PropTypes.string,
    url_prod: PropTypes.string,
    star_prod: PropTypes.number,
    prix_prod: PropTypes.any,
  }).isRequired,
  getSuggestionElastic: PropTypes.func.isRequired,
  updateMatchingsToAdd: PropTypes.func.isRequired,
};

export default ItemSuggestion;
