import React, { Component } from 'react';
import '../../styles/Header.css';
import PropTypes from 'prop-types';

class Header extends Component {
  getLogoPath() {
    const { color } = this.props;
    const logoColor = color === 'black' ? 'blanc' : 'noir';
    return `images/logo-prixan-${logoColor}.png`;
  }

  getStyle() {
    const { color, sticky } = this.props;
    let headerStyle = {
      backgroundColor: color === 'black' ? 'black' : '#EFEFEF',
    };

    if (color === 'black') {
      headerStyle = {
        ...headerStyle,
        color: '#fff',
      };
    }

    if (sticky) {
      headerStyle = {
        ...headerStyle,
        position: 'sticky',
        top: 0,
        zIndex: 2,
      };
    }

    return headerStyle;
  }

  render() {
    const { className, children } = this.props;
    return (
      <header style={this.getStyle()} className={className}>
        <div className="logo">
          <img src={this.getLogoPath()} alt="Prixan Product Search Engine" />
          <div>
            {'Veille automatique du e-commerce'}
          </div>
        </div>

        {children}
      </header>
    );
  }
}

Header.propTypes = {
  color: PropTypes.string,
  sticky: PropTypes.bool,
  className: PropTypes.string,
  children: PropTypes.element.isRequired,
};

Header.defaultProps = {
  color: 'white',
  sticky: false,
  className: null,
};

export default Header;
