import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { t } from 'ttag';
import { Snackbar, Slide } from '@material-ui/core';
import qs from 'qs';
import CompetitorProducts from '../ui/CompetitorProducts';
import Catalog from '../ui/Catalog';
import '../../styles/Popup.css';
import UrlMatching from '../ui/UrlMatching';
import { getModesEnum, cleanElasticResult } from '../../helper/utils';
import callApi from '../../helper/api-fetch';
import { setLocaletranslation } from '../../helper/translate';

const suggestionTab = 0;
const urlTab = 1;

class Popup extends Component {
  constructor(props) {
    super(props);
    const { match, location } = props;
    const locale = match.params.locale ? match.params.locale : 'fr';
    setLocaletranslation(locale);
    this.notifyUser = this.notifyUser.bind(this);
    this.selectProductHandler = this.selectProductHandler.bind(this);
    this.handleSwitchTab = this.handleSwitchTab.bind(this);

    // TODO: there is no security here as to is the user allowed to access this website/product
    const websiteId = parseInt(match.params.website_id, 10);
    const parsed = qs.parse(location.search.replace('?', ''));
    const initBoutiqueId = parsed.boutique_id ? parseInt(parsed.boutique_id, 10) : null;
    this.initProductId = parsed.product_id ? parseInt(parsed.product_id, 10) : null;

    this.state = {
      websiteId: null,
      competitorIds: [],
      websiteStates: {},
      initBoutiqueId,
      selectedProduct: null,
      notification: null,
      activeTab: suggestionTab,
      mode: null,
      currentFilters: {},
    };


    if (!websiteId) {
      this.state.notification = t`Aucun site indiqué`;
    } else {
      this.state.websiteId = websiteId;
    }

    this.handleFilterValueChange = this.handleFilterValueChange.bind(this);
  }

  componentDidMount() {
    const { websiteId } = this.state;
    this.loadCompetitors(websiteId);
  }

  static get suggestionTab() {
    return suggestionTab;
  }

  static get urlTab() {
    return urlTab;
  }

  loadCompetitors(websiteId) {
    const { initBoutiqueId } = this.state;

    callApi({
      path: `/sites/${websiteId}`,
      onSuccess: (data) => {
        const modes = getModesEnum();
        this.setState({ mode: data.marque ? modes.marque : modes.webshop });
        if (data.concurrentIds.length === 0) {
          return Promise.reject(new Error(t`Le site en cours n'a pas de concurrents surveillés`));
        }
        const competitorIds = data.concurrentIds.map(idStr => parseInt(idStr, 10));
        this.setState({
          competitorIds: competitorIds.includes(initBoutiqueId)
            ? [initBoutiqueId] : competitorIds,
        });

        callApi({
          path: `/sites/${websiteId}/website-statuses`,
          onSuccess: (statusData) => {
            this.setState({ websiteStates: statusData });
          },
          onError: error => this.notifyUser(error.message),
        });
        return null;
      },
      onError: error => this.notifyUser(error.message),
      errorMessages: {
        404: t`Le site indiqué n'existe pas`,
      },
    });
  }

  notifyUser(message) {
    this.setState({ notification: message.toUpperCase() });
  }

  handleFilterValueChange(componentId, value) {
    this.setState(state => ({
      currentFilters: {
        ...state.currentFilters,
        [componentId]: value,
      },
    }));
  }

  selectProductHandler(rawElasticDocument) {
    const elasticDocument = cleanElasticResult(rawElasticDocument);
    const { nom_marque: marqueName } = elasticDocument;
    this.setState((state) => {
      const newFilters = marqueName ? { marqueFilter: [marqueName] } : {};
      const { currentFilters } = state;
      return {
        selectedProduct: elasticDocument,
        activeTab: suggestionTab,
        currentFilters: {
          ...currentFilters,
          ...newFilters,
        },
      };
    });
  }

  handleSwitchTab(tabId) {
    this.setState({ activeTab: tabId });
  }

  render() {
    const {
      websiteId, selectedProduct, notification,
      competitorIds, websiteStates, activeTab, mode, currentFilters, initBoutiqueId,
    } = this.state;

    const displayTab = selectedProduct && competitorIds.length > 0;
    const displayCatalog = websiteId;
    const showMatchingArea = displayTab && activeTab === suggestionTab;
    const showUrlArea = displayTab && activeTab === urlTab;

    return (
      <React.Fragment>
        {mode !== null
        && (
        <div className="popup">
          {displayCatalog && (
          <Catalog
            initProductId={this.initProductId}
            websiteId={websiteId}
            selectProductHandler={this.selectProductHandler}
            mode={mode}
            initBoutiqueId={initBoutiqueId}
            notifHandler={this.notifyUser}
          />
          )}

          {showUrlArea && (
          <UrlMatching
            tabSwitchHandler={this.handleSwitchTab}
            product={selectedProduct}
            notifHandler={this.notifyUser}
            mode={mode}
            websiteStates={websiteStates}
            websiteId = {websiteId}
          />
          )}

          {showMatchingArea && (
          <CompetitorProducts
            tabSwitchHandler={this.handleSwitchTab}
            product={selectedProduct}
            competitorIds={competitorIds}
            websiteStates={websiteStates}
            currentFilters={currentFilters}
            onFilterValueChange={this.handleFilterValueChange}
            notifHandler={this.notifyUser}
          />
          )}

          {displayCatalog && !showMatchingArea && !showUrlArea && (
          <img
            className="chooseProduct"
            src={t`https://elastic.prixan.com/images/personnage-select-prod.png`}
            alt={t`sélectionnez un élément dans la liste`}
          />

          )}
        </div>
        )}
        <Snackbar
          anchorOrigin={{ vertical: 'bottom', horizontal: 'center' }}
          message={notification}
          autoHideDuration={6000}
          onClose={() => {
            this.setState({ notification: null });
          }}
          open={!!notification}
          TransitionComponent={props => <Slide {...props} direction="up" />}
        />
      </React.Fragment>
    );
  }
}

Popup.propTypes = {
  match: PropTypes.shape({
    params: PropTypes.shape({
      website_id: PropTypes.string.isRequired,
    }),
  }).isRequired,
  location: PropTypes.shape({
    search: PropTypes.string,
  }).isRequired,
};

export default Popup;
