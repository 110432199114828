import React from 'react';
import PropTypes from 'prop-types';
import Image, { ImageMini } from './Image';
// import styled from '@emotion/styled';

const defaultImageUrl = "https://elastic.prixan.com/images/produitimage.gif"
const ImageProduct = ({ siteId, name, alt }) => {
  const src = siteId && name ? `${process.env.REACT_APP_IMAGES_BUCKET}/${siteId}/${encodeURIComponent(name)}` : defaultImageUrl;
  return <Image src={src} alt={alt} style={{ maxHeight: '120px;', maxWidth: '120px;' }}  />;
};

export const ImageProductMini = ({ siteId, name, alt }) => {
  const src = siteId && name ? `${process.env.REACT_APP_IMAGES_BUCKET}/${siteId}/${encodeURIComponent(name)}` : defaultImageUrl;
  return <ImageMini src={src} alt={alt} style={{ maxHeight: '60px', maxWidth: '60px' }}  />;
};

ImageProductMini.propTypes = {
  siteId: PropTypes.number,
  name: PropTypes.string,
  alt: PropTypes.string,
};

ImageProduct.propTypes = {
  siteId: PropTypes.number,
  name: PropTypes.string,
  alt: PropTypes.string,
};

ImageProduct.defaultProps = {
  alt: '',
};

export default ImageProduct;
