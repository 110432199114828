/* eslint react/forbid-prop-types: 0 */
import React from 'react';
import styled from 'react-emotion';
import PropTypes from 'prop-types';
import { t } from 'ttag';

/**
 * Generates a full path to the image
 *
 * @param {int} siteId
 * @param {string} imageName
 * @returns {string} The path of the image on S3 bucket
 */
const defaultImageUrl = 'https://elastic.prixan.com/images/produitimage.gif';

export function getImagePath(siteId, imageName) {
  if (imageName && siteId) {
    return `${process.env.REACT_APP_IMAGES_BUCKET}/${siteId}/${encodeURIComponent(imageName)}?v=${Date.now()}`;
  }

  return defaultImageUrl;
}

/**
 * Strips all HTML tags from the string
 *
 * @param {string} str
 */
export function stripTags(str) {
  return str.replace(/<\/?[^>]+(>|$)/g, '');
}

/**
 * Create a JSX loader
 */
export function getLoader() {
  return (
    <div className="center">
      <img
        src="https://elastic.prixan.com/images/loading.gif"
        alt="loading"
      />
    </div>
  );
}

/**
 * Extract the domain from an URL
 * @param {string} url
 */
export function getDomainFromUrl(url) {
  const a = document.createElement('a');
  a.href = url;
  return a.hostname;
}

/**
 * Appends http:// if missing at the beggining of URL
 * @param {string} url
 */
export const appendSchemeToUrl = url => (url.match(/^[a-zA-Z]+:\/\//) ? url : (`http://${url}`));


/**
 * Make a clickable span with html content
 * @param {string} htmlAnchor
 * @param {string} url
 * @param {object} attributes
 */
export function makeSpanLink(htmlAnchor, url, attributes = {}) {
  if (url === 'import') {
    return (
      <span />
    );
  }
  return (url !== 'import') && (
    <span
      role="link"
      tabIndex="-1"
      onKeyPress={() => {}}
      onClick={() => {
        window.open(appendSchemeToUrl(stripTags(url)), '_blank');
        return false;
      }}
      dangerouslySetInnerHTML={{ __html: htmlAnchor }}
      {...attributes}
    />
  );
}

/**
 * Helper for listing popup modes
 */
const modesEnum = Object.freeze({ webshop: 0, marque: 1 });
export function getModesEnum() {
  return modesEnum;
}


/** Make a styled reference */
export const Reference = (props) => {
  const { value } = props;

  // Sometimes there are multiple references in elasticsearch
  const allReferencesSet = value
    .filter(Boolean)
    .map(
      arrayOrString => (Array.isArray(arrayOrString) ? arrayOrString : [arrayOrString]),
    )
    .reduce(
      (setAccumulator, refArray) => (new Set([...setAccumulator, ...refArray])),
      [],
    );
  const joinedReferences = [...allReferencesSet].join(' | ');

  return (
    <LocalReference
      className="reference"
      dangerouslySetInnerHTML={{ __html: joinedReferences ? `ref: ${joinedReferences}` : null }}


    />
  );
};
Reference.propTypes = {
  value: PropTypes.arrayOf(PropTypes.any),
};
Reference.defaultProps = {
  value: [],
};

const LocalReference = styled('div')`
      color: #cecece;
      margin: 0px 0px 10px 0px;
      overflow: hidden;
      text-overflow: ellipsis;
      max-height : 40px;
      max-width:500px;
      font-size: 14px; 
      font-style: italic;
`;

export const generateGuid = () => {
  const randLetter = String.fromCharCode(65 + Math.floor(Math.random() * 26));
  const uniqid = randLetter + Date.now();
  return uniqid;
};

/**
 * Clean an elasticsearch result
 *
 * @param {Object} esResult
 */
export const cleanElasticResult = (esResult) => {
  // remove highlights
  const newResult = Object.assign({}, ...Object.keys(esResult).map(k => ({
    [k]: typeof (esResult[k]) === 'string' ? stripTags(esResult[k]) : esResult[k],
  })));
  // Cast id_prod to integer, as it is sometimes in string format in ES
  if ('id_prod' in newResult) {
    newResult.id_prod = parseInt(newResult.id_prod, 10);
  }
  return newResult;
};

export const getCookie = (key) => {
  const cookie = document.cookie.split(';')
    .map(c => c.split('='))
    .find(([cookieName]) => cookieName.trim() === key);
  return cookie && cookie[1];
};

export const getSortOptions = (hasMarques, currentValue) => {
  if (currentValue !== null && currentValue.trim()) {
    return [
      {
        label: t`Trier par pertinence`,
        dataField: '_score',
        sortBy: 'desc',
      },
    ];
  }

  const sortOptions = [
    {
      label: t`Trier par nom`,
      dataField: 'nom_prod.keyword',
      sortBy: 'asc',
    },
    {
      label: t`Trier par prix`,
      dataField: 'prix_prod',
      sortBy: 'desc',
    },
  ];
  if (hasMarques) {
    sortOptions.unshift({
      label: t`Trier par marque`,
      dataField: 'nom_marque.keyword',
      sortBy: 'asc',
    });
  }
  return sortOptions;
};

export const sortOptionsDateCrawl = () => {
  const sortOptions = [
    {
      label: t` `,
      dataField: 'date_last_crawl',
      sortBy: 'desc',
    },
  ];
  return sortOptions;
};
